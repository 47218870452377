namespace PrideApp {

    export enum PrideAlertType { success = <any>"success", error = <any>"danger", warning = <any>"warning", info = <any>"info" }

    export class PrideAlert {

        constructor(private $rootScope, private $elog, private $uibModal, private $timeout, private $q) {
            $rootScope.__alerts__ = [];
            $rootScope.__callout__ = [];
            $rootScope.__calloutHistory__ = [];
        }

        add(type:PrideAlertType, message:string){
            this.$rootScope.__alerts__.push({
                type: type,
                message: message
            });
            this.$elog.info('Add alert:', type, message);

        }

        addModal(type: PrideAlertType, message: string, header: string = null, size: string = 'md') {
            var isolatedScope = this.$rootScope.$new(true);

            isolatedScope.message = message;
            isolatedScope.header = header;

            isolatedScope.type = type;

            var modalInstance = this.$uibModal.open({
                animation: true,
                scope: isolatedScope,
                template: `<div class="box box-{{ type }} box-solid">
        <div class="box-header box-warning" ng-if="header != 'hide-header'">
          <div class="pull-right close-cross"><a href="javascript:void(0);" ng-click="$close();" style="text-decoration: none;"><i class="fa fa-remove"></i></a></div>	
            <h3 class="box-title" ng-bind="header || 'Системное сообщение'"></h3>
        </div>
        <div class="box-body" ng-bind-html="message | trustAsHtml"></div>
        <div class="box-footer">
            <div class="pull-right"><a href="javascript:void(0);" ng-click="$close();" class="btn btn-{{ type }}">Закрыть</a></div> 
        </div>
    </div>`,
                size: size
            });
        }

        addConfirm(type: PrideAlertType, message: string, header: string = null, buttonOk: string = 'Согласен', buttonCancel: string = 'Отменить', size: string = 'md') {
            var isolatedScope = this.$rootScope.$new(true);

            isolatedScope.message = message;
            isolatedScope.header = header;

            isolatedScope.type = type;

            isolatedScope.buttonOk = buttonOk;
            isolatedScope.buttonCancel = buttonCancel;

            var deferred = this.$q.defer();

            isolatedScope.confirm = () => {
                deferred.resolve();
            }

            var modalInstance = this.$uibModal.open({
                animation: true,
                scope: isolatedScope,
                template: `<div class="box box-{{ type }} box-solid">
                    <div class="box-header box-warning">
                      <div class="pull-right close-cross"><a href="javascript:void(0);" ng-click="$close();" style="text-decoration: none;"><i class="fa fa-remove"></i></a></div>	
                        <h3 class="box-title" ng-bind="header || 'Системное сообщение'"></h3> 
                    </div>
                    <div class="box-body" ng-bind-html="message | trustAsHtml"></div>
                    <div class="box-footer">
                        <div class="pull-right">
                            <a href="javascript:void(0);" ng-click="confirm(); $close();" class="btn btn-{{ type }} margin-right-5"><span ng-bind="buttonOk"></span></a>
                            <a href="javascript:void(0);" ng-click="$close();" class="btn btn-default"><span ng-bind="buttonCancel"></span></a>
                        </div> 
                    </div>
                </div>`,

                size: size
            });

            return deferred.promise;
        }

        addCallout(type: PrideAlertType, message:string, expired:number = 5) {
            this.$rootScope.__callout__.push({
                type: type,
                message: message
            });
            this.$rootScope.__calloutHistory__.push({
                type: type,
                message: message
            });

            this.$timeout(() => {
                this.$rootScope.__callout__.shift();
            }, expired * 1000);

            this.$elog.info('Add callout:', type, message);
        }


        getAllMessages(){
            return this.$rootScope.__alerts__;
        }

        removeLastMessage() {
            this.$rootScope.__alerts__.pop();
        }

        closeAll(){
            this.$rootScope.__alerts__ = [];
        }

    }

    angular.module('pride').factory('PrideAlert', ['$rootScope', '$elog', '$uibModal', '$timeout', '$q',  ($rootScope, $elog, $uibModal, $timeout, $q):PrideAlert  => {
        return new PrideAlert($rootScope, $elog, $uibModal, $timeout, $q);
    }]);
}

