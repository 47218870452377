namespace PrideApp {
    angular.module('pride').filter('currencySign', ['$sce', ($sce):any  => {
        return (currency:string) => {
            var currencies = {
                EUR : $sce.trustAsHtml(' &euro;'),
                USD : $sce.trustAsHtml(' $'),
                RUB : $sce.trustAsHtml(' &#8381;')
            };
            return currencies[currency];
        }
    }]);
}