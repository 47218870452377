namespace PrideCore {
    class FormCheckboxList {
        public static $inject:Array<string> = [
            '$elog'
        ];

        private
        list:any;
        model:any;
        type:string;
        dayMode:boolean;
        randomId:number;
        isCheckboxSelected:any;
        checkBoxGroupMode:any;

        constructor(private $elog) {

        }

        $onInit() {
            this.randomId = Math.floor((Math.random()*1000000)+1);

            this.isCheckboxSelected = (elementId) => {
                return _.indexOf(this.model, elementId) > -1;
            }


        }

        toggleCheckbox(elementId){
            if(_.indexOf(this.model, elementId) > -1){
                this.model = _.without(this.model, elementId);
            }else{
                this.model.push(elementId);
            }
            this.$elog.info('Is checkbox group mode: ', !!this.checkBoxGroupMode);
            this.$elog.info('Current checkbox group selected state: ', this.model);
        }

        toggleAll() {
            if(this.model.length){
                this.model = [];
            }else{
                this.model = _.pluck(this.list, 'id')
            }
        }

        toggleWork() {
            this.model = _.pluck(_.where(this.list, {'class' : null} ), 'id');
        }

        toggleWeekDay() {
            this.model = _.pluck(_.where(this.list, {'class' : 'is-weekend'} ), 'id');
        }
    };


    var app = angular.module('pride');

    app.component('formCheckBoxList', {
        bindings: {
            list:  '=',
            model: '=',
            type: '@',
            dayMode: '@'
        },
        template: `
<div ng-if="type != 'vertical'">
    <div class="checkbox" ng-repeat="obj in $ctrl.list">
        <label style="padding-left: 0px;">
            <input style="margin-left: 0px;" type="checkbox" id="checkbox{{ $ctrl.randomId }}-{{ $index }}" ng-click="$ctrl.toggleCheckbox(obj.id)" ng-checked="$ctrl.isCheckboxSelected(obj.id)" class="css-checkbox"/> &nbsp;&nbsp;
            <label style="padding-left: 5px;" for="checkbox{{ $ctrl.randomId }}-{{ $index }}" class="css-label">&nbsp;</label> {{ obj.name  }} 
        </label>
    </div>
    <a href="javascript:void(0);" ng-click="$ctrl.toggleAll()" class="select-toggle-all" ng-show="$ctrl.list.length > 1">выделить все</a>

</div>

<div ng-if="type == 'vertical'">
    <table ng-class="{ 'weekday-mode' : $ctrl.dayMode }" >
        <tr>
            <td ng-repeat="obj in $ctrl.list" class="item{{ obj.id }} {{ obj.class }}">{{ obj.name  }}</td>
        </tr>
        <tr>
            <td ng-repeat="obj in $ctrl.list"><!--
                    --><input type="checkbox" id="checkbox{{ $ctrl.randomId }}-{{ $index }}" ng-click="$ctrl.toggleCheckbox(obj.id)" ng-checked="$ctrl.isCheckboxSelected(obj.id)" class="css-checkbox"/><!--
                    --><label  for="checkbox{{ $ctrl.randomId }}-{{ $index }}" class="css-label"></label><!--
                --></td>
        </tr>
    </table>
    <div ng-if="!dayMode"><a href="javascript:void(0);" ng-click="$ctrl.toggleAll()">выделить все</a></div>
    <div ng-if="dayMode"><a href="javascript:void(0);" ng-click="$ctrl.toggleAll()">все дни</a>&nbsp;|&nbsp;<a href="javascript:void(0);" ng-click="$ctrl.toggleWork()">будни</a>&nbsp;|&nbsp;<a href="javascript:void(0);" ng-click="$ctrl.toggleWeekDay()">выходные</a></div>

</div>`,
        controller: FormCheckboxList
    });

}