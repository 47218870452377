namespace PrideCore {
    class MarkdownArea {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$markdown',
            '$sce'
        ];

        private
            text:string;

        constructor(private SETTINGS, private $markdown, private $sce) {

        }

        $onInit() {

        }

        getHtml(){
            return this.$sce.trustAsHtml(this.$markdown.makeHtml(this.text));
        }

    };


    var app = angular.module('pride');

    app.component('markdownArea', {
        bindings: {
            text: "=",
        },
        template: `<div ng-bind-html="$ctrl.getHtml()" class="markdown-area"></div>`,
        controller: MarkdownArea
    });

}