namespace PrideCore {
    class MoneyField {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$scope'
        ];

        adaptive;
        value;

        constructor(private SETTINGS, private $scope) {
        }

        $onInit() {
            this.$scope.$watch(() => {
                return this.value;
            }, () => {
                this.adaptive = this.value / 100;
            });
        }

    };


    var app = angular.module('pride');

    app.component('moneyField', {
        bindings: {
            value: "=",
            currency: "="
        },
        template: `<table>
                    <tr>
                        <td>
                            <input type="number" class="form-control" ng-model="$ctrl.adaptive" style="width: 100px;" ng-change="$ctrl.value=$ctrl.adaptive*100"/>
                        </td>
                        <td style="padding-left: 8px;"><currency-select  model="$ctrl.currency"></currency-select></td>
                    </tr>
                </table>`,
        controller: MoneyField
    });

}