namespace PrideCore {
    var app = angular.module('pride');

    app.component('sexSelect', {
        bindings: {
            model: "=",
            required: '@'
        },
        template: `<div class="btn-group">
                <a class="btn btn-default" href="javascript:void(0);" ng-class=" { 'active' : $ctrl.model=='m' } " ng-click="$ctrl.model='m'"><i class="fa fa-male"></i></a>
                <a class="btn btn-default"  href="javascript:void(0);"  ng-class=" { 'active' : $ctrl.model=='f' } " ng-click="$ctrl.model='f'"><i class="fa fa-female"></i></a>
            </div>
            [ <span ng-show="!$ctrl.model" class="text-danger"><b>не указан</b></span>
            <b ng-show="$ctrl.model=='m'">мужской</b>
            <b ng-show="$ctrl.model=='f'">женский</b> ]`,
    });

}