namespace PrideApp {
    class CustomerOrderProductsTable {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert',
            '$routeParams',
            'UserSessionParams',
            'orderUtils'
        ];

        private
            sortBy:string;
            total:any;
            products:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private $routeParams,
            private userSessionParams,
            private orderUtils) {
        }


        $onInit() {

            this.$scope.$watch(() => {
                return _.reduce(_.pluck(this.products, 'customer_order_count'), (memo:any, num) => {
                    return memo + num;
                }, 0);
            }, (newVal, oldVal) => {
                if(oldVal == newVal) {
                    return;
                }
                this.recalculate();
            });

            this.sortBy = 'name';
            this.recalculate();

        }

        recalculate() {
            this.total = this.orderUtils.recalculate(this.products);
        }

        removePosition(position) {
            this.$rootScope.$broadcast('customer.order.remove.product', position);
        }


    };


    var app = angular.module('pride');

    app.component('orderProductsTable', {
        bindings: {
            products: '=',
            isChangeable: '@'
        },
        templateUrl: 'templates/core/component/order/order-products-table.html',
        controller: CustomerOrderProductsTable
    });

}