namespace PrideApp {
    class CustomerCurrentOrder {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$timeout',
            '$scope',
            '$rootScope',
            '$location',
            'PrideAlert',
            'UserSessionParams',
            'orderUtils'
        ];

        private
            inProgress:boolean;
            accounts:any[];
            consignees:any[];
            currentAccount:any;
            currentConsignee:any;
            createdOrderParams:any;
            orderParams:any = {};
            total:any;
            products:any;

            customer: any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $timeout,
            private $scope,
            private $rootScope,
            private $location,
            private PrideAlert,
            private userSessionParams:UserSessionParams,
            private orderUtils) {
        }


        $onInit() {
            this.userSessionParams.get('customer_basket').then((data) => {
                this.products = data.user_session_customer_basket;
                // console.log('Products ->>', this.products);
                this.recalculate();
            });

            this.$scope.$on('reload.order.data', () => {
                this.userSessionParams.get('customer_basket').then((data) => {
                    this.products = data.user_session_customer_basket;
                    this.recalculate();
                });
            });

            this.$scope.$watch(() => {

            }, (newVal, oldVal) => {
                if(oldVal == newVal) {
                    return;
                }
                this.userSessionParams.set('customer_basket', this.products);
                this.recalculate();
            });

            // this.$http.get('/api/user/data/').then((response:any) => {
            //
            //     if(response.data.object){
            //         this.accounts = response.data.object.accounts;
            //         this.consignees = response.data.object.consignees;
            //         if(this.accounts.length == 1) {
            //             this.orderParams.accountId = _.first(this.accounts).id;
            //             this.currentAccount = _.first(this.accounts);
            //         }else{
            //             this.userSessionParams.get('account_id').then((data) => {
            //                 this.orderParams.accountId = data.user_session_account_id;
            //                 this.currentAccount = _.findWhere(this.accounts, {id: this.orderParams.accountId});
            //             });
            //         }
            //         if(this.consignees.length == 1) {
            //             this.orderParams.consigneeId = _.first(this.consignees).id;
            //             this.currentConsignee = _.first(this.consignees);
            //         }else{
            //             this.userSessionParams.get('consignee_id').then((data) => {
            //                 this.orderParams.consigneeId = data.user_session_consignee_id;
            //                 this.currentConsignee = _.findWhere(this.consignees, {id: this.orderParams.consigneeId});
            //             });
            //         }
            //     }
            // }, (error) => {
            //     this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            // });

            this.$scope.$watch(() => {
                return this.currentAccount && this.currentConsignee && this.customer && (this.currentAccount.id + this.currentConsignee.id + this.customer.id) ;
            }, (newVal, oldVal) => {
                if(this.currentConsignee) {
                    this.orderParams.consigneeId = this.currentConsignee.id
                    this.orderParams.consigneeSign = this.currentConsignee.sign
                }
                if(this.currentAccount){
                    this.orderParams.accountId = this.currentAccount.id
                    this.orderParams.accountSign = this.currentAccount.sign
                }
                if(this.customer){
                    this.orderParams.customerId = this.customer.id
                    this.orderParams.customerSign = this.customer.sign

                }
            });

            this.$scope.$watch(() => {
                return _.reduce(_.pluck(this.products, 'customer_order_count'), (memo:any, num) => {
                    return memo + num;
                }, 0);
            }, (newVal, oldVal) => {
                if(oldVal == newVal) {
                    return;
                }
                this.userSessionParams.set('customer_basket', this.products);
                this.recalculate();
            });


            this.$scope.$on('customer.order.change.product', (event, data) => {

                if(!data || !data.id) return;

                if(!this.products) {
                    this.products = [];
                }

                var product:any = _.findWhere(this.products, {id: data.id});

                if(product){
                    product.customer_order_count = data.customer_order_count;
                }else{
                    this.products.push(data);
                }

                this.products = _.sortBy(this.products, 'brand_name');

                this.userSessionParams.set('customer_basket', this.products);
                this.recalculate();
            });

            this.$scope.$on('customer.order.remove.product', (event, data) => {
                if(!data || !data.id) return;
                this.removePosition(data);
            });

        }

        removePosition(position) {
            var fd = _.findWhere(this.products, {id: position.id});
            if(fd){
                this.products = _.without(this.products, fd);
            }
        }

        recalculate() {
            if(!this.products) return;
            this.total = this.orderUtils.recalculate(this.products);

            this.$rootScope.$broadcast('customer.order.recalculate.total', this.total);
            this.$rootScope.$broadcast('customer.order.get.order.products', this.products);
        }

        empty() {
            this.PrideAlert.addConfirm(PrideAlertType.error, 'Вы точно ухотите удалить все позиции из закзаза?.', 'Очистка заказа', 'Да, удалить все позиции').then(() => {
                this.products = [];
                this.userSessionParams.set('customer_basket', this.products);
                this.recalculate();
                this.$rootScope.$broadcast('close.edit.order');
            });
        }

        save() {
            if(!this.orderParams.accountId){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан лицевой счет.', 'Ошибка при сохранении заказа');
                return;
            }

            if(!this.orderParams.consigneeId){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан грузополучатель.', 'Ошибка при сохранении заказа');
                return;
            }

            this.orderParams.products = this.products;

            this.inProgress = true;

            this.$http.post('/order/create/', this.orderParams).then( (response) => {
                this.createdOrderParams = response.data;
                $('html, body').animate({ scrollTop: 0 }, 'fast');
                this.$location.url((this.createdOrderParams.is_custom_redirect ? 'admin/order' + this.createdOrderParams.order.id : 'order/' + this.createdOrderParams.order.id + '/true') );
                this.resetParams()
                this.$rootScope.$broadcast('customer.order.create.order', this.total);
                this.inProgress = false;

            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                this.inProgress = false;
            });

        }

        resetParams() {
            this.products = [];
            this.orderParams = {};
            this.total = {};
        }

        saveAsPreorder() {
            this.$http.post('/order/save-state/', this.orderParams).then( (response) => {
                this.PrideAlert.addModal(PrideAlertType.success, 'Предзаказ успешно сохранен! Вы можете просмотреть список предзаказов в левом меню.', 'Предзаказ сохранен');
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                this.inProgress = false;
            });
        }

    };


    var app = angular.module('pride');

    app.component('editOrder', {
        bindings: {
            onlyView: '@',
            currentAccount: '=',
            currentConsignee: '=',
            customer: '='
        },
        templateUrl: 'templates/core/component/order/edit-order.html',
        controller: CustomerCurrentOrder
    });

}