namespace PrideApp {
    class CustomerOrders {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            '$routeParams',
            'numberUtils',
            '$momentjs',
            'PrideAlert'
        ];

        private
            searchWaitStatus:boolean;
            statuses:any;
            searchParams:any;
            noOrdersStatus:string;
            orders:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private $routeParams,
            private numberUtils,
            private $momentjs,
            private PrideAlert) {
        }


        $onInit() {
            this.$http.get('/admin/dictionary').then( (response:any) => {
                this.statuses = response.data.statuses;
            });
            this.searchParams = {};
            this.searchParams.hide_canceled = true;

            this.$scope.$on('admin.order.search.history.apply', (event, data) => {
                if(!data) {
                    this.searchParams.create_date_from = this.$momentjs().add('-7', 'day').toDate();
                    this.searchParams.create_date_to = new Date();
                    this.search();
                    return;
                }

                console.log('data', data.data);

                angular.forEach(data.data, (item, key) => {
                    if(_.isString(item) && item.match(/^\d{4}-\d{2}-\d{2}T/)){
                        data.data[key] = new Date(item);
                    }
                });

                if(data.data.status_id){
                    data.data.status_id = parseInt(data.data.status_id);
                }

                this.searchParams = data.data;
                this.search();
            });
        }

        setDate(days){
            this.searchParams.create_date_to = new Date();
            this.searchParams.create_date_from = this.$momentjs().add('-' + days, 'day').toDate();
        }

        search(){
            this.orders = [];
            this.searchWaitStatus = true;

            var historyItem = new UniversalHistoryItem(this.$momentjs);

            historyItem.data = this.searchParams;
            historyItem.convertToName(this.searchParams, {
                inn: 'ИНН',
                create_date_from: 'от',
                create_date_to: 'до',
                status_id: 'Статус (id)',
                order_id: 'Заказ (id)',
                customer: 'Клиент'
            });

            this.$rootScope.$broadcast('admin.order.search.history.add',  historyItem);
            this.$rootScope.$broadcast('admin.order.search.history.close');

            this.$http.post('/admin/order/list', this.searchParams).then( (response) => {
                    this.orders = response.data;
                    this.noOrdersStatus = this.orders.length ? 'no' : 'yes';
                    angular.forEach(this.orders, (order) => {
                        if(order.bill_volume){
                            order.bill_volume = this.numberUtils.adjustDecimal('round', order.bill_volume, -6);
                        }
                        if(order.bill_weight){
                            order.bill_weight = this.numberUtils.adjustDecimal('round', order.bill_weight, -2);
                        }
                        order.volume = this.numberUtils.adjustDecimal('round', order.volume, -6);
                        order.weight = this.numberUtils.adjustDecimal('round', order.weight, -2);
                        order.price = this.numberUtils.adjustDecimal('round', order.price, -2);

                        angular.forEach(order.bills, (bill) => {
                            bill.bill_volume = this.numberUtils.adjustDecimal('round', bill.bill_volume, -6);
                            bill.bill_weight = this.numberUtils.adjustDecimal('round', bill.bill_weight, -2);
                        });
                    }); 
                    this.searchWaitStatus = false;
                }, (error) => {
                    this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                    this.searchWaitStatus = false;
                }
            );

        }

        checkKey(event){
            if(event.keyCode == 13){
                this.search();
            }
        }

    };


    var app = angular.module('pride');

    app.component('adminOrderSearch', {
        bindings: { },
        templateUrl: 'templates/admin/component/order/order-search.html',
        controller: CustomerOrders
    });

}