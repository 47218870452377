namespace PrideApp {

    export interface Product {
        id: string;
        code: string;
        articul: string;
        name: string;
        brand_name: string;
        category_id: string;
        weight: string;
        volume: string;
        taxes: string;
        details: string;
        list_image: string;
        price: number;
        base_price: number;
        discount: number;
        rc_price: number;
        rc_base_price: number;
        cnt: string;
        is_new: boolean;
        is_top: boolean;
    }

    class ProductSearch {
        public static $inject: Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$momentjs',
            '$scope',
            '$rootScope',
            'PrideAlert',
            'ProductSearch',
            'UserSessionParams'
        ];


        private
        customerId: number;

        isSrMode: boolean;
        showOrder: boolean;
        customer: any;
        orderParams: any;
        total: any;
        customerProducts: any[];
        customerProductToId: any;
        currentAccount: any;
        currentConsignee: any;
        notFoundStatus: boolean;
        searchWaitStatus: boolean;
        currentPage: number = 1;
        products: Product[];
        productsToDisplay: Product[];
        productsToDisplayLeftCount: number;
        banners: { url: string, img: string }[];

        constructor(
            private SETTINGS,
            private $elog,
            private $http: ng.IHttpService,
            private $momentjs,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private ProductSearch,
            private userSessionParams: UserSessionParams) {
        }


        $onInit() {
            this.$scope.$on('product.search.form.event', (event, data) => {
                this.search(data);
            });

            this.$scope.$on('customer.order.recalculate.total', (event, data) => {
                this.total = data;
            });

            this.$scope.$on('reload.order.data', (event, data) => {
                this.showOrder = true;
            });

            this.$scope.$on('customer.order.get.order.products', (event, data) => {
                this.customerProducts = data;
                this.customerProductToId = {};
                angular.forEach(this.customerProducts, (product) => {
                    this.customerProductToId[product.id] = product.customer_order_count;
                });
            });

            this.$scope.$on('close.edit.order', () => {
                this.showOrder = false;
            });

            if (!this.isSrMode) {
                this.$http.get('/baninfo/').then((response: any) => {
                        this.banners = response.data;
                    }
                );


                this.$http.get('/api/user/data/').then(
                    (response: any) => {
                        this.orderParams = this.orderParams || {};
                        this.customer = response.data.object;
                        if (this.customer.accounts.length == 1) {
                            this.currentAccount = _.first(this.customer.accounts);
                            this.orderParams.accountId = this.currentAccount.id;
                            this.setAccountId();
                        } else {
                            this.userSessionParams.get('account_id').then((data) => {
                                this.orderParams.accountId = data.user_session_account_id;
                                this.currentAccount = _.findWhere(this.customer.accounts, {id: this.orderParams.accountId});
                                if (this.currentAccount && this.currentAccount.info) {
                                    this.currentAccount.sale_limit = this.currentAccount.info.credit_limit - this.currentAccount.info.arrears_all;
                                    this.currentAccount.info.overdue_all = this.currentAccount.info.overdue7 + this.currentAccount.info.overdue30 + this.currentAccount.info.overdue60 + this.currentAccount.info.overdue60plus;
                                }
                            });
                        }
                        if (this.currentAccount && this.currentAccount.info) {
                            this.currentAccount.sale_limit = this.currentAccount.info.credit_limit - this.currentAccount.info.arrears_all;
                            this.currentAccount.info.overdue_all = this.currentAccount.info.overdue7 + this.currentAccount.info.overdue30 + this.currentAccount.info.overdue60 + this.currentAccount.info.overdue60plus;
                        }
                        if (this.customer.consignees.length == 1) {
                            var firstConsignee: any = _.first(this.customer.consignees);
                            this.orderParams.consigneeId = firstConsignee.id;
                            this.setConsigneeId();
                        } else {
                            this.userSessionParams.get('consignee_id').then((data) => {
                                this.orderParams.consigneeId = data.user_session_consignee_id;
                                this.setConsigneeId();
                            });
                        }
                    });
            } else {
                this.$scope.$watch(() => {
                    return this.customerId;
                }, (oldVal, newVal) => {
                    if (oldVal != newVal && this.customerId) {
                        this.$http.get('/admin/customer-data/' + this.customerId + '/').then((response: any) => {
                            this.orderParams = this.orderParams || {};
                            this.customer = response.data.object;
                            if (this.customer.accounts.length == 1) {
                                this.currentAccount = _.first(this.customer.accounts);
                                this.orderParams.accountId = this.currentAccount.id;
                                this.setAccountId();
                            }
                            if (this.currentAccount.info) {
                                this.currentAccount.sale_limit = this.currentAccount.info.credit_limit - this.currentAccount.info.arrears_all;
                                this.currentAccount.info.overdue_all = this.currentAccount.info.overdue7 + this.currentAccount.info.overdue30 + this.currentAccount.info.overdue60 + this.currentAccount.info.overdue60plus;
                            }
                            if (this.customer.consignees.length == 1) {
                                var firstConsignee: any = _.first(this.customer.consignees);
                                this.orderParams.consigneeId = firstConsignee.id;
                                this.setConsigneeId();
                            }
                        });
                    }
                });
            }

        }

        gotoOrder() {
            $('body,html').animate({scrollTop: 525}, 800);
            this.showOrder = true;
        }

        search(data) {

            this.searchWaitStatus = true;
            this.notFoundStatus = false;
            data.accountId = this.orderParams.accountId;
            this.currentPage = 1;
            this.productsToDisplay = [];
            this.productsToDisplayLeftCount = 0;

            this.$rootScope.$broadcast('close.edit.order');

            var historyItem = new UniversalHistoryItem(this.$momentjs);

            historyItem.data = {
                brand_name: data.brand_name,
                category_id: data.category_id,
                inStockOnly: data.inStockOnly,
                search_string: data.search_string
            }
            historyItem.convertToName(historyItem.data, {
                brand_name: 'бренд',
                category_id: 'категория',
                inStockOnly: 'в наличии',
                search_string: 'строка поиcка'
            });

            this.$rootScope.$broadcast('product.search.history.add', historyItem);
            this.$rootScope.$broadcast('product.search.history.close');


            this.ProductSearch.get(data).then((products) => {
                angular.forEach(products, (product) => {
                    if (this.customerProductToId && this.customerProductToId[product.id]) {
                        product.customer_order_count = this.customerProductToId[product.id];
                    }
                });
                this.searchWaitStatus = false;
                if (!products.length) {
                    this.notFoundStatus = true;
                }
                this.products = products;

                this._showProductToDisplay();


            }, (error) => {
                this.searchWaitStatus = false;
                this.PrideAlert.addModal(PrideAlertType.error, 'По указанным Вами параметрам мы не смогли найти ни одного продукта.', 'По Вашем запросу ничего не найдено');
            });

        }

        _getWeekIndex() {
            let now = new Date();
            let onejan = new Date(now.getFullYear(), 0, 1);
            let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);

            return week
        }

        _showProductToDisplay() {
            this.productsToDisplay = this.products.slice(0, this.currentPage * this.SETTINGS.SEARCH_RESULTS_PER_PAGE);
            var productsLeft = this.products.length - this.productsToDisplay.length;
            this.productsToDisplayLeftCount = productsLeft > this.SETTINGS.SEARCH_RESULTS_PER_PAGE ? this.SETTINGS.SEARCH_RESULTS_PER_PAGE : productsLeft;
        }

        increaseDisplayedPositions() {
            this.currentPage++;
            this._showProductToDisplay();
        }

        setAccountId() {
            this.userSessionParams.set('account_id', this.orderParams.accountId);
            this.currentAccount = _.findWhere(this.customer.accounts, {id: parseInt(this.orderParams.accountId)});
            // console.log('Current Account', this.customer.accounts, this.orderParams.accountId, this.currentAccount);
        }

        setConsigneeId() {
            this.userSessionParams.set('consignee_id', this.orderParams.consigneeId);
            this.currentConsignee = _.findWhere(this.customer.consignees, {id: parseInt(this.orderParams.consigneeId)});
        }

        getRandom(num, from = 1) {
            return from + Math.floor(Math.random() * num);
        }
    };


    var app = angular.module('pride');

    app.component('productSearch', {
        bindings: {
            isSrMode: '@'
        },
        templateUrl: 'templates/core/component/product-search/product-search.html',
        controller: ProductSearch
    });

}