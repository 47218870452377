namespace PrideApp {
    class CustomerCreateOrderFromFile {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            '$routeParams',
            'numberUtils',
            'PrideAlert',
            'UserSessionParams'
        ];

        private
        isOpen:boolean;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private $routeParams,
            private numberUtils,
            private PrideAlert,
            private userSessionParams) {
        }


        $onInit() {
            this.$scope.$on('file.upload.on.success.item.upload.excel.order', (event, data) => {
                this.isOpen = false;
                this.$rootScope.$broadcast('reload.order.data');
                console.log('Fired -> file.upload.on.complete.item.upload.excel.order', data);

                if(data.wrongPositions.length){
                    var errorPositions = '<table class="table">';
                    angular.forEach(data.wrongPositions, (item) => {
                        errorPositions += '<tr><td>';
                        errorPositions += item.join('</td><td>');
                        errorPositions += '</td></tr>';
                    });

                    this.PrideAlert.addModal( PrideAlertType.warning,
                        'Ваш заказ был успешно загружен, но в нем найдены строки с ошибками. Эти строки не будут показаны в заказе, но Вы сможете добавить их через поиск. Вы можете исправить ошибки и загрузить файл заново, или приступить к редактированию заказа. ' + 'Загружено <b>' + data.result.length + ' строк</b>' + ' <h3>Ошибочные позиции</h3>' + errorPositions, 'Заказ успешно загружен (есть ошибки)', 'lg' );
                    errorPositions += '</table>';
                }else{
                    this.PrideAlert.addModal( PrideAlertType.success, 'Ваш заказ был успешно загружен. Вы можете приступить к его редактированию.' + 'Загружено <b>' + data.result.length + ' строк</b>', 'Заказ успешно загружен' );
                }
            });
            this.$scope.$on('file.upload.on.error.item.upload.excel.order', (event, data) => {
                this.isOpen = false;
                console.log('Fired -> file.upload.on.error.item.upload.excel.order', data);
                this.PrideAlert.addModal( PrideAlertType.error, 'Мы не смогли загрузить ваш заказ. ' + data.message, 'Заказ не был загружен' );
            });
        }

    };


    var app = angular.module('pride');

    app.component('createOrderFromFile', {
        bindings: { },
        templateUrl: 'templates/core/component/order/create-order-from-file.html',
        controller: CustomerCreateOrderFromFile
    });

}