namespace PrideApp {
    class TypeaheadSearch {
        public static $inject:Array<string> = [
            '$scope',
            '$elog',
            '$http',
            'SETTINGS'
        ];

        private
            url:string;
            model:any;

        constructor(private $scope:any, private $elog:any, private $http:any, private SETTINGS) {
        }

        getByQuery(val) {
            return this.$http.get(this.url  + val + '/', {
                params: {
                    sensor: false
                }
            }).then((response) => {
                var data = response.data.map( (item) => {
                    return item;
                });
                return data;
            });
        }

        select($item, $model, $label, $event) {
            this.model = $item;
        }

    };


    var app = angular.module('pride');

    app.component('typeaheadSearch', {
        bindings: {
            url: '@',
            template: '@',
            minLength: '@',
            minWait: '@',
            model: '='
        },
        template: `<div class="typeahead-search"><input type="text" class="form-control"
                                   ng-model="$ctrl.model"
                                   placeholder="Введите значение..."
                                   uib-typeahead="item as item.name for item in $ctrl.getByQuery($viewValue)"
                                   typeahead-min-length="$ctrl.minLength || 3"
                                   typeahead-wait-ms="$ctrl.minWait|| 500"
                                   typeahead-on-select="$ctrl.select($item, $model, $label, $event);" 
                                   typeahead-template-url="{{$ctrl.template}}"  ></div>`,
        controller: TypeaheadSearch
    });
}