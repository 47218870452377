namespace PrideApp {
    angular.module('pride').filter('gotoSite', [():any  => {
        return function(input, code = null) {
            if(!input){
                return;
            }

            if(!code){
                return
            }

            return 'https://www.pride.ru/catalog/item-by-code/' + code;

            // if(code && code.toString().indexOf('0') == 0){
            //     code = code.substr(1);
            // }
            //
            // switch(input){
            //     case "Jonnesway":
            //         return "https://www.jonnesway.ru/catalog/qr/code/" + code + "/";
            //     case "Ombra":
            //         return "https://ombratools.ru/catalog/qr/code/" + code + "/";
            //     case "Thorvik":
            //         return "https://thorvik.ru/catalog/qr/code/" + code + "/";
            //     case "FILL inn":
            //         return "https://www.fill-inn.com//catalog/qr/code/" + code + "/";
            //     default:
            //         return null;
            // }
        };
    }]);
}