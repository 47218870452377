namespace PrideApp {
    var app = angular.module('pride');

    app.directive('markdownEditor', ['SETTINGS', '$window', (SETTINGS, $window): ng.IDirective => {
        return {
            restrict : 'AE',
            scope: {
                text  : '='
            },
            template: `<textarea class="form-control markdown-editor" ng-model="text"></textarea>`,
            link: (scope: any, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) => {
                var simpleMDE = null;
                var pauseEvent = false;
                $(document).ready(() => {
                    simpleMDE = new $window.SimpleMDE({ element: $(".markdown-editor", element)[0], spellChecker: false });
                    simpleMDE.codemirror.on("change", function(){
                        if(!pauseEvent) {
                            scope.text = simpleMDE.value();
                            scope.$apply();
                        }
                    });
                    scope.$watch('text', (newVal, oldVal) => {
                        if(scope.text != simpleMDE.value(  )){
                            pauseEvent = true;
                            simpleMDE.value( scope.text || ''  );
                            pauseEvent = false;
                        }
                    });
                });
            }
        }
    }]);
}