namespace PrideApp {
    class CustomerOrder {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert',
            '$routeParams',
            'UserSessionParams',
            'orderUtils'
        ];

        private
            adminMode:boolean;
            total:any;
            products:any;
            order:any;
            isNew: boolean;
            customer: any;
            manager: any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private $routeParams,
            private userSessionParams,
            private orderUtils) {
        }


        $onInit()
        {
            this.isNew = this.$routeParams.isNew;

            this.$http.get( (this.adminMode ? '/admin/' : '/') + 'order/get-by-id/'+ this.$routeParams.orderId +'/').then((response:any) => {
                this.order = response.data;

                angular.forEach(this.order.positions, (position) => {
                    var product = position.product;
                    if(this.order.bills && this.order.bills.length){
                        product.customer_order_count = _.isNumber(position.cnt_actual) ? position.cnt_actual : position.cnt;
                        product.price = position.price_actual;
                        if(position.cnt_actual == 0){
                            product.is_removed=true;
                        }
                    }else{
                        if(position.cnt_actual === 0){
                            product.customer_order_count = 0;
                        }else{
                            product.customer_order_count = position.cnt_actual || position.cnt;
                        }
                        product.price = position.price_actual || position.price;
                    }
                    product.customer_order_count_prev = position.cnt;
                    product.price_prev = position.price;
                    product.is_new = position.is_new;
                });

                this.products = _.pluck(this.order.positions, 'product');
                this.recalculate();
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });

            if(!this.adminMode){

            }
            this.$http.get('/api/user/data/').then(
                (response:any) => {
                    this.customer = response.data.object;
                    this.manager = response.data.manager;
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли получить данные', 'Ошибка при получении данных клиента');
                });

        }

        duplicate(orderId)
        {
            this.$http.get( '/order/duplicate-by-id/'+ orderId +'/').then((response:any) => {
                window.location.href = '/#!/search';
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        recalculate()
        {
            if(!this.products) return;
            this.total = this.orderUtils.recalculate(this.products);

            this.$rootScope.$broadcast('customer.order.recalculate.total', this.total);
            this.$rootScope.$broadcast('customer.order.get.order.products', this.products);
        }

        managerList()
        {
            if(this.customer.customer_managers && this.customer.customer_managers.length && this.order && this.order.id){
                let selectedManagerId = this.order.customer_managers.map(manager => manager.id);
                let modifiedManagers = this.customer.customer_managers.map(manager => {
                    manager.is_selected = selectedManagerId.indexOf(manager.id) > -1
                    return manager
                })
                return modifiedManagers;
            }else{
                return [];
            }
        }

        toggleManager(manager)
        {
            this.$http.post( '/order/link-customer-manager-to-order/', {customer_manager_id: manager.id, customer_order_id: this.order.id} ).then((response:any) => {
                this.order.customer_managers = response.data.customer_managers
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

    };


    var app = angular.module('pride');

    app.component('order', {
        bindings: {
            orderId: '@',
            adminMode: '@'

        },
        templateUrl: 'templates/core/component/order/order.html',
        controller: CustomerOrder
    });

}