namespace PrideApp {
    var app = angular.module('pride');

    app.directive('animateCss', [(): ng.IDirective => {
        return {
            restrict : 'A',
            scope: {
                animateCssEffect  : '@'
            },
            link: (scope: any, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) => {
                $(element).on('click', () => {
                    if(!$(element).hasClass('animated')){
                        $(element).addClass('animated');
                    }

                    $(element).addClass(scope.animateCssEffect);

                    setTimeout(() => {
                        $(element).removeClass(scope.animateCssEffect);
                    }, 2000)
                });
            }
        }
    }]);
}