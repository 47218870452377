namespace PrideCore {
    class CountrySelect {
        countries = [];

        public static $inject:Array<string> = [
            '$http',
            'SETTINGS'
        ];

        private vm:any = this;

        constructor(
            private $http:ng.IHttpService,
            private SETTINGS
        ) {
        }

        $onInit() {
            this.$http.get( this.SETTINGS.DICTIONARY_COUNTRY,
                {
                    params: { sensor: false  },
                    cache: true
                }
            ).then((response:any) => {
                this.countries = response.data;
            });
        }
    };

    var app = angular.module('pride');

    app.component('countrySelect', {
        bindings: {
            model: "=",
            ngRequired: '@'
        },
        template: `<select ng-options="item.iso_alpha_two as item.name_ru for item in $ctrl.countries" class="form-control" ng-model="$ctrl.model" ng-required="{{ $ctrl.ngRequired }}"><option value="">-- выберите страну --</option></select>`,
        controller: CountrySelect
    });

}