namespace PrideApp {
    /**
     * Позволяет отключать вывод лога в зависимости от IS_DEBUG в настройках
     */

    export interface ELog {
        log(...args: any[]):   void;
        info(...args: any[]):  void;
        warn(...args: any[]):  void;
        error(...args: any[]): void;
        debug(...args: any[]): void;
        table(...args: any[]): void;
        time(...args: any[]): void;
        timeEnd(...args: any[]): void;
    }

    angular.module('pride').
        factory('$elog', ['SETTINGS', '$log', '$window', (SETTINGS, $log, $window):ELog  => {

        if(SETTINGS.IS_DEBUG){
            var elog:ELog = angular.copy($log);
            elog.table = $window.console.table;
            elog.time = $window.console.time;
            elog.timeEnd = $window.console.timeEnd;
        }else{
            var elog:ELog = {
                log:   () => {},
                info:  () => {},
                warn:  () => {},
                error: () => {},
                debug: () => {},
                table: () => {},
                time: () => {},
                timeEnd: () => {},
            };
        }
        return elog;
    }]);
}