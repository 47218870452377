namespace PrideApp {
    class ProductSearchForm {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert'
        ];

        private
            createNewOrderFromFile:boolean;
            currentCategory: any;
            searchParams:any;
            categories:any[]
            brands:any[];

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert) {
        }


        $onInit() {
            this.$http.get('/brands/').then( (response) => {
                this.brands = <any> response.data;
            })
            this.loadCategories();

            this.$scope.$watch(() => {
                return this.searchParams && this.searchParams.category_id;
            }, () => {
                if(!this.searchParams){
                    return;
                }
                if(this.searchParams.category_id){
                    this.currentCategory = _.findWhere(this.categories, { id: this.searchParams.category_id });
                }else{
                    this.currentCategory = null;
                }
                console.log('Current category:', this.currentCategory);
            });

            this.$scope.$on('product.search.history.apply', (event, data) => {
                console.log('data', data.data);
                this.searchParams = data.data;
                this.search();
            });

            this.$scope.$on('file.upload.on.complete.all.upload.excel.order', (data) => {
                this.createNewOrderFromFile = false;
                this.$rootScope.$broadcast('reload.order.data');
            });


        }

        loadCategories()
        {
            this.$http.post('/category_builder/', {
                    brand_name: this.searchParams && this.searchParams.brand_name ? this.searchParams.brand_name : null
                }).then( (response) => {
                this.categories = <any> response.data;
                this.$rootScope.$broadcast('select2:need:to:update', true);
            })
        }

        checkKey(event){
            if(event.keyCode == 13){
                this.search();
            }
        }

        getBrandsBySelectedCategories()
        {
            if(this.searchParams.category_id){
                this.currentCategory = _.findWhere(this.categories, { id: this.searchParams.category_id });
            }else{
                this.currentCategory = null;
            }
        }

        search(){
            if(!this.searchParams){
                this.PrideAlert.addModal(PrideAlertType.error, 'Вы не указали параметры поиска. Нужно указать название, артикул, код, бренд или категорию.', 'Не указаны параметры поиска');
                return;
            }
            this.$rootScope.$broadcast('product.search.form.event', this.searchParams);
        }


    };


    var app = angular.module('pride');

    app.component('productSearchForm', {
        bindings: {
            isEnabled: '='
        },
        templateUrl: 'templates/core/component/product-search/product-search-form.html',
        controller: ProductSearchForm
    });

}