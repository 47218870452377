namespace PrideApp {
    angular.module('pride').filter('priceFormat', [():any  => {
        return (input:string, $isRound = false, isRoundForamted = false) => {

            // возможно потом для иностранных цен будем применять другой разделитель
            if(!input) return '0.00';


            input = input.toString();

            var separation = " ";
            var before = _.first(input.split('.'));

            // console.log('Index of -> ', input, input.indexOf('.'));

            if(input.indexOf('.') > 0){
                var after = _.last(input.split('.'));
                after = after.substring(0, 2);
                if(!after) after = '00';
                if(after.length == 1) after = after + '0';
            }else{
                after = '00';
            }

            var result = before.split( /(?=(?:\d{3})+$)/ ).join( separation );

            result = $isRound ? result : result + '.' + (isRoundForamted ? '<small>' + after + '</small>' : after) ;
            return result;
        }
    }]);
}