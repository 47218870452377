namespace PrideCore {
    class FormSelect {
        public static $inject:Array<string> = [
            '$scope'
        ];

        private
        label:string;
        name:string;
        model:any;
        isShowRequiredError:boolean;
        isRequired: boolean;
        list: any;
        elementSize: number;
        elementClass:string;  // ts-vertical  - переформатирует элементы
        noValueLabel:string;
        preventNoValue:string;
        labelSize:number;
        quietMode: boolean;
        isDisabled:boolean;
        selectedValue:any;

        constructor(private $scope) {

        }

        $onInit() {
            this.labelSize = this.labelSize || 2;

            if(!this.noValueLabel) {
                this.noValueLabel = 'выберите значение';
            }

            if(this.quietMode) {
                this.selectedValue = _.findWhere(this.list, { id: this.model });

                this.$scope.$watch('model', (newVal, oldVal)=>{
                    this.selectedValue = _.findWhere(this.list, { id: this.model });
                });

                this.$scope.$watch('list', (newVal, oldVal)=>{
                    this.selectedValue = _.findWhere(this.list, { id: this.model });
                });
            }            
        }
    };


    var app = angular.module('pride');

    app.component('formSelect', {
        bindings: {
            label:         '@',
            name:          '@',
            model:         '=',
            isShowRequiredError: '=',
            isRequired:    '@',
            list:          '=',
            elementSize:   '@',
            elementClass:   '@',  // ts-vertical  - переформатирует элементы
            noValueLabel:  '@',
            preventNoValue: '@',
            labelSize:     '@',
            quietMode:     '@',
            isDisabled :   '='
        },
        template: `<div class="form-group  {{ $ctrl.elementClass }}">
    <label ng-if="$ctrl.labelSize > 0" class="col-sm-{{ $ctrl.labelSize }} control-label">{{ $ctrl.label }} <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
    <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
        <div ng-show="!$ctrl.quietMode || $ctrl.showSelectState">
            <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
                <select  class="form-control" ng-options="item.id as item.name  for item in $ctrl.list" ng-model="$ctrl.model" ng-change="$ctrl.showSelectState=false" ng-required="$ctrl.isRequired" ng-disabled="$ctrl.isDisabled">
                    <option ng-if="!$ctrl.preventNoValue" value="">-- выберите из списка --</option>
                </select>
                <span ng-if="$ctrl.isRequired" class="form-field-error-message ng-clock" ng-show="$ctrl.isShowRequiredError">Необходимо заполнить поле "{{ label }}" </span>
            </span>
        </div>
        <div ng-show="$ctrl.quietMode && !$ctrl.showSelectState">
            <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
                <label class="control-label"><a href="javascript:void(0);" class="select-quite-mode" ng-click="$ctrl.showSelectState=true">{{ $ctrl.selectedValue.name }}</a></label>
            </span>
        </div>
    </div>
</div>`,
        controller: FormSelect
    });

}