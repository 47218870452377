namespace PrideApp {
    class TruncatedElement {
        public static $inject:Array<string> = [];

        private
        num:string;
        model:any;

        constructor() {
        }

        isMore(){
            return this.model.length >= this.num;
        }
    };


    var app = angular.module('pride');

    app.component('truncatedElement', {
        bindings: {
            model: '@',
            num: '@'
        },
        template: `
            <span ng-bind="$ctrl.model|truncate:$ctrl.num" ng-show="!$ctrl.extend"></span><a class="margin-left-10" href="javascript:void(0);" ng-if="$ctrl.isMore()" ng-show="!$ctrl.extend" ng-click="$ctrl.extend=true">подробнее</a>
            <span ng-bind="$ctrl.model" ng-if="$ctrl.isMore()" ng-show="$ctrl.extend" ng-cloak></span> &nbsp;&nbsp; <a href="javascript:void(0);" ng-if="$ctrl.isMore()" ng-show="$ctrl.extend" ng-click="$ctrl.extend=false" ng-if="$ctrl.isMore()">скрыть</a>`,
        controller: TruncatedElement
    });
}