namespace PrideApp {
    class LeftMenu {
        public static $inject:Array<string> = ['$http', '$scope', 'PrideAlert'];

        private
        orderCount:number;

        constructor(
            private $http,
            private $scope,
            private PrideAlert
        ) {
        }


        $onInit() {
           //  this.getParams();
        }

        getParams() {
            this.$http.get('/admin/get-actual-statistic/').then((response:any) => {
                this.orderCount = response.data.order_count;
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }


    };


    var app = angular.module('pride');

    app.component('leftMenuAdmin', {
        bindings: {

        },
        templateUrl: 'templates/admin/component/user-interface/left-menu.html',
        controller: LeftMenu
    });

}