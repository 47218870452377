namespace PrideApp {
    class AuthForm {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            'PrideAlert'
        ];


        private
            showReminderForm:boolean;
            showSendPasswordInfoBlock:boolean;
            showSendPasswordInfoBlockError:string;
            url:string;
            remind:string;
            redirect:string;
            label:string;
            customer:any;
            email:string;
            password:string;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private PrideAlert) {
        }


        $onInit() {

        }

        login() {
            if(!this.login){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан email', 'Ошибка авторизаци');
                return;
            }

            if(!this.password){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан пароль', 'Ошибка авторизаци');
                return;
            }

            this.$http.post( this.url ? this.url : '/api/user/login/', { 'email' : this.email, 'password' : this.password }).then(
                (response:any) => {
                    window.location.href = this.redirect ? this.redirect : '/';
                },
                (error:any) => {
                    console.log('Auth Response Error: ', error);
                    this.PrideAlert.addModal(PrideAlertType.error, error.data.message, 'Ошибка авторизаци');
                });
        }

        sendPassword() {
            if(!this.login){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан email', 'Ошибка авторизаци');
                return;
            }

            this.$http.post( this.remind ? this.remind : '/remind/password/', { 'email' : this.email } ).then(
                (response:any) => {
                    this.showSendPasswordInfoBlock = false;
                    this.showSendPasswordInfoBlockError = null;
                    if(response.data.status == 'ok'){
                        this.showSendPasswordInfoBlock = true;
                    }else{
                        this.showSendPasswordInfoBlockError = response.data.error;
                    }
                    this.showReminderForm = false;
                },
                (error:any) => {
                    console.log('Auth Response Error: ', error);
                    this.PrideAlert.addModal(PrideAlertType.error, error.data.message, 'Ошибка авторизаци');
                });
        }


    };


    var app = angular.module('pride');

    app.component('authForm', {
        bindings: {
            'url' : '@',
            'remind' : '@',
            'redirect': '@',
            'label' : '@'
        },
        templateUrl: 'templates/core/component/customer/auth-form.html',
        controller: AuthForm
    });

}