namespace PrideApp {


    export class StringUtil {
        truncate( text, n, useWordBoundary) {
            text = text ? text : '';
            n = parseInt(n);
            var isTooLong = text.length > n,
                s_ = isTooLong ? text.substr(0,n-1) : text;
            s_ = (useWordBoundary && isTooLong) ? s_.substr(0,s_.lastIndexOf(' ')) : s_;
            return  isTooLong ? s_ + '...' : s_;
        }
    }

    angular.module('pride').factory('stringUtils', [ ():any  => {
        return new StringUtil();
    }]);
}