namespace PrideApp {
    angular.module('pride').filter('splitParams', ['$filter', ( $filter ):any  => {
        return (input:string) => {
            var translated:string = $filter('translate')(input);
            return _.map(translated.split(/\|/), (item) => {
                var sitem = item.split(/\:/);
                return { id: sitem[0], name: sitem[1] };
            });
        }
    }]);
}