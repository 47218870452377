namespace PrideApp {
    var app = angular.module('pride');

    app.directive('fileUpload', ['SETTINGS', '$window', '$rootScope', '$uibModal',  (SETTINGS, $window, $rootScope, $uibModal): ng.IDirective => {
        return {
            restrict : 'AE',
            scope: {
                link: '@',
                url : '@'
            },
            link: (scope: any, element: ng.IAugmentedJQuery, attributes: ng.IAttributes) => {
                element.click( () => {
                    var isolatedScope:any = $rootScope.$new(true);

                    isolatedScope.link = scope.link;
                    isolatedScope.url = scope.url;

                    var modalInstance = $uibModal.open({
                        animation: true,
                        scope: isolatedScope,
                        template: `<file-uploader link="{{link}}" url="{{url}}"></file-uploader>`,
                        size: 'lg'
                    });

                    $rootScope.$on('file.upload.close.modal', (event, data) => {
                        modalInstance.close();
                    });
                    $rootScope.$on('file.upload.list.update', (event, data) => {
                        modalInstance.close();
                    });
                });
            }
        }
    }]);
}