namespace PrideApp {
    declare var io : any;

    export class SocketIO {

        public static AVIA_BOOKING_ADD_TO_WORK_EVENT : string = 'socket.io.avia.booking.add.to.work.event';

        private
            socket:any;

        constructor(private $elog:any, private $rootScope:ng.IRootScopeService){
            return;

            /**
            if(_.isUndefined(io)) {
                return;
            }
            
            console.log('Try to connect to server...');

            this.socket = io.connect('http://localhost:3000/', {
                timeout: 3000
            });
            this.socket.on('connect', () => {
                console.log('Client has connected to the server!');

                this.socket.on(SocketIO.AVIA_BOOKING_ADD_TO_WORK_EVENT, (data) => {
                    this.$rootScope.$broadcast(SocketIO.AVIA_BOOKING_ADD_TO_WORK_EVENT, data);
                    console.log('Received a message from the server!', SocketIO.AVIA_BOOKING_ADD_TO_WORK_EVENT, data);
                });
            });

            return this.socket;
          */
        }

    }

    angular.module('pride').factory('socketIO', ['$elog', '$rootScope', ($elog, $rootScope):any  => {
        return new SocketIO($elog, $rootScope);
    }]);

}