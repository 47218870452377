namespace PrideCore {
    class FormCheckbox {
        public static $inject:Array<string> = [
        ];

        private
        randomId:number;
        model:any;
        checkedFunction:any;
        clickFunction:any;
        isDisabled:boolean;

        constructor() {

        }

        $onInit() {
            this.randomId = Math.floor((Math.random()*1000000)+1);
        }
    };


    var app = angular.module('pride');

    app.component('formCheckbox', {
        bindings: {
            model:    '=',
            checkedFunction:  '&',
            clickFunction:    '&',
            isDisabled: '='
        },
        template: `<input type="checkbox" id="checkbox{{ $ctrl.randomId }}" ng-model="$ctrl.model" ng-cheched="$ctrl.checkedFunction()" ng-disabled="$ctrl.isDisabled" ng-click="$ctrl.clickFunction()" value="1" class="css-checkbox" />
<label for="checkbox{{ $ctrl.randomId }}" class="css-label">&nbsp;</label>`,
        controller: FormCheckbox
    });

}