namespace PrideApp {
    declare var FileUploader : any;

    class FileList {
        public static $inject: Array<string> = [
            'SETTINGS',
            '$http',
            '$rootScope',
            'PrideAlert'
        ];

        private
            typeCode: string;
            id: number;
            files: any[];

        constructor(private SETTINGS, private $http, private $rootScope:ng.IRootScopeService, private PrideAlert:PrideAlert) {
        }

        $onInit() {

            if(!this.typeCode){
                console.error('Не указан тип typeCode');
                return;
            }

            if(!this.typeCode){
                console.error('Не указан id');
                return;
            }

            this.update();

            this.$rootScope.$on('file.upload.list.update', () => {
                this.update();
            });
        }

        update() {
            this.$http.get(this.SETTINGS.UPLOADER_BASE_PATH + 'list/' + this.typeCode + '/' + this.id + '/').then((response: any) => {
                this.files = response.data.files;
                var permissions = response.data.permissions;
                this.files = _.map(this.files, (item:any) => {
                    item.allowToRemove = _.indexOf(permissions, item.id) > -1;
                    return item;
                })
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        remove(file) {
            file.processing_publish = true;
            this.$http.get(this.SETTINGS.UPLOADER_BASE_PATH + 'remove/' + file.id + '/' + file.verifying_code + '/').then((response: any) => {
                this.files = _.without(this.files, file);
                file.processing_publish = false;
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        publish(file) {
            file.processing_publish = true;
            console.log('File: ', file);
            this.$http.get(this.SETTINGS.UPLOADER_BASE_PATH + 'publish/' + file.id + '/' + file.verifying_code + '/').then((response:any) => {
                file.publish_id = response.data.publish_id;
                file.processing_publish = false;
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });

        }

        unpublish(file) {
            file.processing_publish = true;
            this.$http.get(this.SETTINGS.UPLOADER_BASE_PATH + 'unpublish/' + file.id + '/' + file.verifying_code + '/').then((response:any) => {
                file.publish_id = null;
                file.processing_publish = false;
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }


    };


    var app = angular.module('pride');

    app.component('fileList', {
        bindings: {
            typeCode: '@',
            id: '@'
        },
        templateUrl: 'templates/core/component/elements/file-list.html',
        controller: FileList
    });

}