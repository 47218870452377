namespace PrideCore {
    class Alert {
        public static $inject:Array<string> = [
            'PrideAlert'
        ];

        constructor(private PrideAlert) {
        }

        closeAlert () {
            this.PrideAlert.closeAll();
        }
    }


    var app = angular.module('pride');

    app.component('globalAlert', {
        bindings: {
            duration: '@'
        },
        template: `<uib-alert ng-repeat="alert in $root.__alerts__"  type="{{ alert.type }}" dismiss-on-timeout="{{ $ctrl.duration }}000" close="$ctrl.closeAlert()"><i class="fa fa-warning" style="font-size: 200%; float: left; padding: 0 10px; "></i> <div ng-bind-html="alert.message | trustAsHtml"></div> </uib-alert>`,
        controller: Alert
    });

}