namespace PrideApp {
    class CustomerOrders {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            '$routeParams',
            'numberUtils',
            'PrideAlert',
            'UserSessionParams'
        ];

        private
           noOrdersStatus:string;
          orders:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private $routeParams,
            private numberUtils,
            private PrideAlert,
            private userSessionParams) {
        }


        $onInit() {
            this.$http.get('/order/get-list/').then( (response) => {
                this.orders = response.data;
                this.noOrdersStatus = this.orders.length ? 'no' : 'yes';
                angular.forEach(this.orders, (order) => {
                    if(order.bill_volume){
                        order.bill_volume = this.numberUtils.adjustDecimal('round', order.bill_volume, -6);
                    }
                    if(order.bill_weight){
                        order.bill_weight = this.numberUtils.adjustDecimal('round', order.bill_weight, -2);
                    }
                    order.volume = this.numberUtils.adjustDecimal('round', order.volume, -6);
                    order.weight = this.numberUtils.adjustDecimal('round', order.weight, -2);
                    order.price = this.numberUtils.adjustDecimal('round', order.price, -2);

                    angular.forEach(order.bills, (bill) => {
                        bill.bill_volume = this.numberUtils.adjustDecimal('round', bill.bill_volume, -6);
                        bill.bill_weight = this.numberUtils.adjustDecimal('round', bill.bill_weight, -2);
                    });
                });
                console.log(this.orders);
                }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                return;
            });
        }

    };


    var app = angular.module('pride');

    app.component('orders', {
        bindings: { },
        templateUrl: 'templates/core/component/order/orders.html',
        controller: CustomerOrders
    });

}