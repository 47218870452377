namespace PrideCore {
    class FormCheckBoxGroup {
        public static $inject:Array<string> = [
            '$elog'
        ];

        private
        label:string;
        list:any;
        model:any;
        type:string;
        dayMode:boolean;
        labelSize:number;

        constructor(private $elog) {

        }

        $onInit() {

        }


    };


    var app = angular.module('pride');

    app.component('formCheckBoxGroup', {
        bindings: {
            label: '@',
            list:  '=',
            model: '=',
            type: '@',
            dayMode: '@',
            labelSize: '@'
        },
        template: `<form-surrounder
        label-size="{{ $ctrl.labelSize }}"
        label="{{ $ctrl.label }}">
        <form-check-box-list
                day-mode="$ctrl.dayMode"
                list="$ctrl.list"
                model="$ctrl.model"></form-check-box-list>

</form-surrounder>`,
        controller: FormCheckBoxGroup
    });

}