namespace PrideCore {
    class FormSurrounder {
        public static $inject:Array<string> = [
        ];

        private
        label:string;
        isRequired:boolean;
        elementSize:number;
        elementClass:string;
        labelSize:number


        constructor() {

        }

        $onInit() {
            this.labelSize = this.labelSize || 3;
        }
    };


    var app = angular.module('pride');

    app.component('formSurrounder', {
        bindings: {
            label:       '@',
            isRequired:  '=',
            elementSize: '@',
            elementClass:   '@',
            labelSize:   '@'
        },
        transclude: true,
        template: `<div class="form-group  {{ $ctrl.elementClass }}">
    <label  class="col-sm-{{ $ctrl.labelSize }} control-label">{{ $ctrl.label  }} <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
    <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
        <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
            <ng-transclude></ng-transclude>
        </span>
    </div>
</div>`,
        controller: FormSurrounder
    });

}