namespace PrideCore {
    class FormInput {
        public static $inject:Array<string> = [
        ];

        private
        label:string;
        name:string;
        model:any;
        isShowRequiredError:boolean;
        isRequired:boolean;
        type:string;
        elementSize:number;
        elementClass:string;
        labelSize:number;
        placeholder:string;

        constructor(private SETTINGS, private $markdown, private $sce) {

        }

        $onInit() {
            this.labelSize = this.labelSize || 3;
            this.placeholder = this.placeholder||'введите значение...'
        }
    };


    var app = angular.module('pride');

    app.component('formInput', {
        bindings: {
            label:         '@',
            name:          '@',
            model:         '=',
            isShowRequiredError: '=',
            isRequired:    '@',
            type:          '@',
            elementSize:   '@',
            elementClass:   '@',
            labelSize:     '@',
            placeholder:    '@',
            postfix:        '@',
            prefix:        '@',
            minLenght:     '@',
            maxLength:     '@'
        },
        template: `
<div ng-if="$ctrl.type != 'textarea'">
    <div class="form-group  {{ $ctrl.elementClass }}">
        <label  class="col-sm-{{ $ctrl.labelSize }} control-label"><span ng-bind="$ctrl.label"></span> <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
        <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
            <span class="col-xs-1" ng-bind-html="$ctrl.prefix" ng-if="$ctrl.prefix"></span>
            <span class="col-xs-{{ $ctrl.elementSize || 12 }}"><!--
                --><input  type="{{ $ctrl.type || 'text' }}" class="form-control" ng-model="$ctrl.model" name="{{ $ctrl.name }}"  placeholder="{{ $ctrl.placeholder }}" ng-required="$ctrl.isRequired"><!-- 
                --><div ng-if="$ctrl.isRequired" class="form-field-error-message ng-cloak" ng-show="$ctrl.isShowRequiredError">Необходимо заполнить поле "{{ $ctrl.label }}" </div>
            </span>
            <span class="col-xs-1" ng-bind-html="$ctrl.postfix" ng-if="$ctrl.postfix"></span>
        </div>
    </div>
</div>

<div  ng-if="$ctrl.type == 'textarea'">
    <div class="form-group">
        <label  class="col-sm-{{ $ctrl.labelSize }} control-label">{{ $ctrl.label  }} <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
        <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
            <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
                <textarea  name="{{ $ctrl.name }}"  rows="6" class="form-control" ng-model="$ctrl.model" placeholder="{{ $ctrl.placeholder }}" ng-required="$ctrl.isRequired"></textarea>
                <div ng-if="$ctrl.isRequired" class="form-field-error-message ng-cloak" ng-show="isShowRequiredError">Необходимо заполнить поле "{{ "$ctrl.label }}" </div>
            </span>
        </div>
    </div>
</div>
`,
        controller: FormInput
    });

}