namespace PrideApp {
    class ManagerEditor {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert'
        ];

        private
        manager:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert) {
        }

        save() {
            if(!this.$scope.managerEditor.$valid){
                this.PrideAlert.addModal( PrideAlertType.error, 'Проверьте правильность заполнения полей формы' );
                return;
            }

            if(!this.manager.id && !this.manager.password){
                this.PrideAlert.addModal(PrideAlertType.error, 'Не указан пароль', 'Ошибка при добавлении менеджера');
                return
            }

            if(this.manager.password){
                if(this.manager.password.length < 6){
                    this.PrideAlert.addModal(PrideAlertType.error, 'Пароль должен быть не менее 6 символов', 'Ошибка при добавлении менеджера');
                    return
                }

                if(!this.manager.password.match(/\d/) || !this.manager.password.match(/[A-z]/) ){
                    this.PrideAlert.addModal(PrideAlertType.error, 'Пароль должен содержать как минимум одну букву и одну цифру', 'Ошибка при добавлении менеджера');
                    return
                }

                if(this.manager.password.match(/[А-я]+/)){
                    this.PrideAlert.addModal(PrideAlertType.error, 'Пароль не должен включать русские буквы', 'Ошибка при добавлении менеджера');
                    return
                }
            }


            this.$http.post('/api/customer-manager/save/', this.manager).then(
                (response:any) => {
                    this.$rootScope.$broadcast('save.customer.manager', response.data)
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли добавить менеджера: ' + error.message, 'Ошибка при добавлении менеджера');
                });
        }

    };


    var app = angular.module('pride');

    app.component('managerEditor', {
        bindings: {
            manager: "="
        },
        templateUrl: 'templates/core/component/customer/manager-editor.html',
        controller: ManagerEditor
    });

}