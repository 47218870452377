namespace PrideApp {
    export enum UniversalMessageType {
        TYPE_ORDER = <any>"1"
    }

    class UniversalMessages {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$timeout',
            '$interval',
            '$window',
            'PrideAlert'
        ];

        const
            MARK_AS_READ_PERIOD = 30;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $timeout:ng.ITimeoutService,
            private $interval:ng.IIntervalService,
            private $window,
            private PrideAlert) {
        }

        private
          intervalReload = null;
          markAsReadTimeOut = null;
          visibleRecords:number = 5;
          newMessage:UniversalMessage;
          messages:UniversalMessage[];
          objectTypeId:string;
          objectTypeValue:string;
          conversationCreatorId:number;
          searchWaitStatus:boolean;
          addNewMessageWaitStatus:boolean;

        $onInit() {
            this.conversationCreatorId = this.getConversationCreatorId();
            this.newMessage = new UniversalMessage();

            this.$scope.$on( UniversalMessageEvent.EVENT_UNIVERSAL_MESSAGE_UPDATE, (event, data) => {
               var messages = <UniversalMessage[]>_.where(data, { 'object_type_id' : parseInt(this.objectTypeId), 'object_type_value' : parseInt(this.objectTypeValue) });
                if(!this.messages || messages.length > this.messages.length) {
                    this.messages = messages;
                }
            });

            this.markAsReadTimeOut = this.$timeout( () => { this.markAsRead(); }, this.MARK_AS_READ_PERIOD * 1000 );
            this.intervalReload = this.$timeout( () => { this.load(); }, this.MARK_AS_READ_PERIOD * 1000 );
        }

        $onDestroy(){
            this.$timeout.cancel(this.markAsReadTimeOut);
            this.$timeout.cancel(this.intervalReload);
        }

        getConversationCreatorId() {
            if(this.messages && this.messages.length){
                var firstMessage:UniversalMessage = _.last(this.messages);
                return firstMessage.manager_id;
            }
            return null;
        }

        markAsRead() {
            var that:UniversalMessages = this;
            var readyToMarkAsReadId = [];
            angular.forEach(this.messages, (message:UniversalMessage) => {
                if(!message.read_statuses || !message.read_statuses.length){
                    readyToMarkAsReadId.push(message.id);
                }
            });
            if(readyToMarkAsReadId.length){
                this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'mark-as-read/',
                    {
                        'messageId' : readyToMarkAsReadId
                    },
                    {
                        params: {
                            sensor: false
                        }
                    }
                ).then( (response) => {
                    angular.forEach(that.messages, (message:UniversalMessage) => {
                        if(!message.read_statuses || !message.read_statuses.length){
                            message.read_statuses = [{'createDate' : 'now'}];
                        }
                    });
                }, (error) => {
                    this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                });
            }
        }

        load() {
            this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'list/',
                {
                    'objectTypeId' : this.objectTypeId,
                    'objectTypeValue' : this.objectTypeValue
                }
            ).then( (response:any) => {
                this.messages = response.data;
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        addMessage() {
            if( !this.newMessage.body ) {
                return;
            }

            var that:UniversalMessages = this;

            this.addNewMessageWaitStatus = true;

            this.newMessage.object_type_id = parseInt(this.objectTypeId);
            this.newMessage.object_type_value = parseInt(this.objectTypeValue);

            this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'add/',
                this.newMessage,
                {
                    params: {
                        sensor: false
                    }
                }
            ).then((response) => {
                var message = <UniversalMessage>response.data;
                if(!that.messages) that.messages = [];
                that.messages.unshift( message );
                that.addNewMessageWaitStatus = false;
                that.newMessage.body = null;
                this.PrideAlert.addCallout( PrideAlertType.success, 'Сообщение по данной броне добавлено' );
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                that.addNewMessageWaitStatus = false;
            });
        }

    };


    var app = angular.module('pride');

    app.component('universalMessages', {
        bindings: {
            objectTypeId:        '@',
            objectTypeValue:     '@',
            textareaPosition:    '@',
            messages:            '='
        },
        template: `<div class="direct-chat-messages" > 
                        <div ng-if="$ctrl.textareaPosition=='top'">
                            <markdown-editor text="$ctrl.newMessage.body"></markdown-editor>
                            <button class="btn btn-default margin-top-5"  ng-disabled="$ctrl.addNewMessageWaitStatus " ng-click="$ctrl.addMessage()"><i class="fa" ng-class=" { 'fa-commenting' : !$ctrl.addNewMessageWaitStatus, 'fa-spin fa-refresh' : $ctrl.addNewMessageWaitStatus } "></i> Добавить сообщение</button>
                            <p>&nbsp;</p>
                        </div>
                        <div class="direct-chat-msg" ng-class=" { 'right' : message.manager_id != 1 } " ng-repeat="message in $ctrl.messages" ng-if="$index < $ctrl.visibleRecords">
                            <div class="direct-chat-info clearfix">
                                <span class="direct-chat-name pull-left" ng-bind="message.manager.name"></span>
                                <span class="direct-chat-timestamp pull-right" ng-bind="message.create_date | momentjs: 'DD.MM.YYYY HH:mm'"></span>
                                <span class="pull-right margin-right-10" ng-if="!message.read_statuses.length"><b class="text-danger"><i class="fa fa-clock-o"></i> новое</b></span>
                            </div>
                            <img class="direct-chat-img" src="/bundles/app/images/anonymous.jpg">
                            <div class="direct-chat-text"><markdown-area text="message.body"></markdown-area></div>
                        </div>
                        <div class="text-right">
                            <a href="javascript:void(0);" ng-click="$ctrl.load()" class="margin-right-15"><i class="fa fa-refresh"></i>  обновить</a>
                            <a href="javascript:void(0);" ng-click="$ctrl.markAsRead()" class="margin-right-15"><i class="fa fa-eye-slash"></i>  пометить все сообщения как прочитанные</a>
                            <a href="javascript:void(0);" ng-click="$ctrl.visibleRecords=1000"  ng-if="$ctrl.messages.length >= $ctrl.visibleRecords"><i class="fa fa-comments-o"></i> показать еще <span ng-bind="$ctrl.messages.length-5"></span> <span ng-bind="$ctrl.messages.length-5|wordEndings:'сообщение':'сообщения':'сообщений'"></span></a>
                        </div>
                        <div ng-if="$ctrl.textareaPosition!='top'">
                            <markdown-editor text="$ctrl.newMessage.body"></markdown-editor>
                            <button class="btn btn-default margin-top-5"  ng-disabled="$ctrl.addNewMessageWaitStatus " ng-click="$ctrl.addMessage()"><i class="fa" ng-class=" { 'fa-commenting' : !$ctrl.addNewMessageWaitStatus, 'fa-spin fa-refresh' : $ctrl.addNewMessageWaitStatus } "></i> Добавить сообщение</button>
                        </div>
                </div>`,
        controller: UniversalMessages
    });

}

//  <div class="pull-right" >**text** = <b>text</b>, *text* = <i>text</i>, \`text\`=<span style="color: #c7254e; background-color: #f9f2f4; border-radius: 4px; padding: 2px 4px; font-size: 90%;">text</span></div>
