namespace PrideApp {


    class UniversalMessagesPanel {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$rootScope',
            '$interval',
            '$window',
            '$uibModal',
            'PrideAlert',

        ];

        const
            UPDATE_PERIOD = 120;

        private
            interval:any;
            objectTypeId:number;
            messages = [];

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $rootScope,
            private $interval:ng.IIntervalService,
            private $window,
            private $uibModal,
            private PrideAlert) {
        }

        $onInit() {
            var that:UniversalMessagesPanel = this;
            this.update();
            this.$interval( () => {
                that.update();
            }, this.UPDATE_PERIOD * 1000 );

        }

        $onDestroy(){
            this.$interval.cancel(this.interval);
        }

        update(){
            var that:UniversalMessagesPanel = this;

            return this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'unread/',
                {
                    objectTypeId:     this.objectTypeId
                },
                {
                    params: {
                        sensor: false
                    }
                }
            ).then((response:any) => {
                that.messages = response.data;
            }, (error) => {
                console.log('Universal Message Update Error: ', error.data.message, error.data.trace);
                // that.PrideAlert.add( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        markAsRead(message){
            var that:any = this;
            this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'mark-as-read/',
                {
                    'messageId' : [message.id]
                },
                {
                    params: {
                        sensor: false
                    }
                }
            ).then( (response) => {
                var mes = _.findWhere(that.messages, { 'id' : message.id });
                that.messages = _.without(that.messages, mes);
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }

        go(message){
            this.markAsRead(message);
            var that:any = this;
            that.openObject({ bookingId : message.object_type_value });
        }

        open(){
            var that:any= this;
            var isolatedScope:any = this.$rootScope.$new(true);
            isolatedScope.messages = this.messages;
            isolatedScope.markAsRead = (message) => {
                that.markAsRead(message);
                isolatedScope.messages = _.without(isolatedScope.messages, message);
            }

            isolatedScope.go = (message) => {
                that.go(message);
            }

            var modalInstance = this.$uibModal.open({
                animation: true,
                scope: isolatedScope,
                template: `
                <div class="box ">
                    <div class="box-header with-border">
                        <h3 class="box-title">Новые сообщения</h3>
                    </div>
                    <div class="box-body " style="max-height: 500px; overflow: auto;">
                        <div  ng-repeat="message in messages" >
                            <div class=" clearfix">
                                <b class=" pull-left" ng-bind="message.manager.name"></b>
                                <i class=" pull-right" ng-bind="message.create_date | momentjs: 'DD.MM.YYYY HH:mm'"></i>
                            </div>
                            <div class=""><markdown-area text="message.body"></markdown-area></div>
                            <div>
                                <a href="javascript:void(0);" ng-click="markAsRead(message);"><i class="fa fa-thumbs-o-up"></i> пометить как прочитанное</a> &nbsp; &nbsp;
                                <a href="javascript:void(0);" ng-click="go(message);$close();"><i class="fa fa-search"></i> открыть <span ng-bind="message.context"></span></a>
                            </div>
                            <hr>
                        </div>                    
                    </div>
                </div>`,
                size: 'lg'
            });
        }


    };


    var app = angular.module('pride');

    app.component('universalMessagesPanel', {
        bindings: {
            objectTypeId:   '@',
            openObject:     '&',
            objectName:     '@'
        },
        template: `<div class="text-right"><a href="javascript:void(0);" ng-click="$ctrl.open();">Всего непрочитанных сообщений:</a> <span class="label label-danger" ng-bind="$ctrl.messages.length"></span></div>
                        

`,
        controller: UniversalMessagesPanel
    });

}

//  <div class="pull-right" >**text** = <b>text</b>, *text* = <i>text</i>, \`text\`=<span style="color: #c7254e; background-color: #f9f2f4; border-radius: 4px; padding: 2px 4px; font-size: 90%;">text</span></div>
