namespace PrideApp {
    class UserAbout {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            'PrideAlert'
        ];

        private
        customer:any;
        manager:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private PrideAlert) {
        }


        $onInit() {
            this.$http.get('/api/user/data/').then(
                (response:any) => {
                    this.customer = response.data.object;
                    this.manager = response.data.manager;
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли получить данные', 'Ошибка при получении данных клиента');
                });
        }


    };


    var app = angular.module('pride');

    app.component('userAbout', {
        bindings: {

        },
        templateUrl: 'templates/core/component/customer/user-about.html',
        controller: UserAbout
    });

}