namespace PrideApp {
    angular.module('pride').filter('trustAsHtml', ['$sce', ($sce): any => {
        var _storage = {};
        return (input: string) => {
            if(angular.isUndefined(_storage[input])){
                _storage[input] = $sce.trustAsHtml(input);
            }
            return _storage[input];
        }
    }]);
}