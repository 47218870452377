namespace PrideApp {
    export class LocalStorage {
        private _debug = true;

        constructor(private localStorageService:any, private $elog:any){
        }

        get(key) {
            var data = this._getStorage().get(key);
            if(this._debug) this.$elog.info('Get Local Storage key: ', key, data);
            return data;
        }

        set(key, val) {
            this._getStorage().set(key, val);
            if(this._debug) this.$elog.info('Set Local Storage key: ', key, val);
        }

        private _getStorage(){
            return this.localStorageService;
        }
    }

    angular.module('pride').factory('localStorage', ['localStorageService', '$elog', (localStorageService, $elog):any  => {
        return new LocalStorage(localStorageService, $elog);
    }]);

}