namespace PrideApp {
    angular.module('pride').filter('wordEndings', ['$elog', ($elog):any  => {
        return function(input, var1, var2, var3) {
            input = parseInt(input) % 100;
            if (input>=11 && input<=19)  return var3;
            var i = input % 10;
            if(i ==  1) return var1;
            if(i > 0 && i < 5) return var2;
            return var3;
        };
    }]);
}