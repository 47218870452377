namespace PrideApp {
    export class UserSessionParams {

        private
            _isAuth = null;
            _authData = null;

        constructor(private $rootScope, private $elog, private $http, private SETTINGS) {
            $rootScope.__alerts__ = [];
        }

        get( name ) {
            return this.$http.get(this.SETTINGS.USER_SESSION_BASE_PATH + 'get_user_session_params/'  + name + '/', {
                params: {
                    sensor: false
                }
            }).then(function(response){
                return response.data;
            });
        }

        set( name, data ) {
            return this.$http.post(this.SETTINGS.USER_SESSION_BASE_PATH + 'set_user_session_params/'  + name + '/', data, {
                params: {
                    sensor: false
                }
            }).then(function(response){
                return response.data;
            });
        }

        add( name, data ) {
            return this.$http.post(this.SETTINGS.USER_SESSION_BASE_PATH + 'add_user_session_params/'  + name + '/', data, {
                params: {
                    sensor: false
                }
            }).then(function(response){
                return response.data;
            });
        }

        remove (name) {
            return this.$http.get(this.SETTINGS.USER_SESSION_BASE_PATH + 'remove_user_session_params/'  + name + '/', {
                params: {
                    sensor: false
                }
            }).then(function(response){
                return response.data;
            });
        }

        delete (name, data) {
            return this.$http.post(this.SETTINGS.USER_SESSION_BASE_PATH + 'delete_user_session_params/'  + name + '/', data, {
                params: {
                    sensor: false
                }
            }).then(function(response){
                return response.data;
            });
        }

        login(login, password) {
            var that = this;

            return this.$http.post(this.SETTINGS.USER_AUTH_BASE + 'login/' , {
                login: login,
                password: password
            }).then(function(response){
                that._isAuth = true;
                that._authData = response.data;
                return that._authData;
            });
        }

        logout() {
            var that = this;

            return this.$http.get(this.SETTINGS.USER_AUTH_BASE + 'logout/').then(function(response){
                that._isAuth = false;
                that._authData = null;
                return null;
            });
        }

        authParams(){
            var that = this;

            if( this._isAuth === null ){
                return this.$http.get(this.SETTINGS.USER_AUTH_BASE + 'data/').then(function(response){
                    that._isAuth = true;
                    that._authData = response.data;
                    return that._authData;
                }, () => {
                    that._isAuth = false;
                    that._authData = null;
                    return false;
                });
            }else if(this._isAuth === true){
                return that._authData;
            }else{
                return false;
            }
        }
    }


    angular.module('pride').factory('UserSessionParams', ['$rootScope', '$elog', '$http', 'SETTINGS', ($rootScope, $elog, $http, SETTINGS):UserSessionParams  => {
        return new UserSessionParams($rootScope, $elog, $http, SETTINGS);
    }]);
}