namespace PrideApp {
    export class ProductSearch {

        constructor(private $http, private SETTINGS) {
        }

        get( filters ) {
            return this.$http.post('/product_search/', filters).then((response) => {
                var products = response.data;
                angular.forEach(products, (product) => {
                    product.customer_order_count = 0;
                    product.volume = parseFloat(product.volume);
                });
                return products;
            });
        }

    }

    angular.module('pride').factory('ProductSearch', ['$http', 'SETTINGS', ($http, SETTINGS):ProductSearch  => {
        return new ProductSearch($http, SETTINGS);
    }]);
}