namespace PrideApp {
    angular.module('pride').filter('momentjs', [ '$momentjs',  ( $momentjs : moment.MomentStatic ):any  => {
        return (input:string, format:string) => {
            // исключаем влияние timezone
            if(input){
                if(typeof input === 'string'){
                    input = input.replace(/T/, ' ').replace(/\+\d{4}$/, '');
                }
                // console.log('MomentJS:', input);
                return $momentjs(input).format(format || 'DD.MM.YYYY')
            }else{
                return '';
            }
        }
    }]);
}