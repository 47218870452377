namespace PrideApp {
    class Managers {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            '$uibModal',
            'PrideAlert'
        ];

        private
        managers:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private $uibModal,
            private PrideAlert) {
        }

        edit(manager) {

            let isolatedScope = this.$rootScope.$new(true);
            let cmanager = angular.copy(manager)
            cmanager.password = ''
            isolatedScope.manager = cmanager

            let modalInstance = this.$uibModal.open({
                animation: true,
                scope: isolatedScope,
                template: "<manager-editor manager='manager'/>",
                size: 'md'
            });

            this.$scope.$on('save.customer.manager', (event, data) => {
                if(this.managers.filter( m => m.id == data.id ).length){
                    angular.forEach(this.managers, (row, $index) => {
                        if (row.id === data.id) {
                            this.managers[$index] = data
                        }
                    })
                }else{
                    this.managers.push(data)
                }
                modalInstance.close();
            });
        }

        remove(manager) {
            this.$http.post('/api/customer-manager/remove/', manager).then(
                (response:any) => {
                    this.managers.splice(this.managers.indexOf(manager), 1)
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли удалить менеджера', 'Ошибка при удалении менеджера');
                });
        }


    };


    let app = angular.module('pride');

    app.component('managers', {
        bindings: {
            managers: "="
        },
        templateUrl: 'templates/core/component/customer/managers.html',
        controller: Managers
    });

}