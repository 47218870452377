namespace PrideCore {
    class ButtonGroup {
        selected;

        public static $inject:Array<string> = [
            'SETTINGS'
        ];

        private vm:any = this;

        constructor(
            private SETTINGS
        ) {
        }

        $onInit() {

        }

        isSelected(id) {
            return this.selected && this.selected.indexOf(id) > -1;
        }

        select(id){
            if( this.isSelected(id) ){
                this.selected = _.without(this.selected, id);
            }else{
                if(!this.selected) this.selected = [];
                this.selected.push(id);
            }
            this.selected = _.uniq(this.selected);
        }
    };

    var app = angular.module('pride');

    app.component('buttonGroup', {
        bindings: {
            list: "=",
            selected: '=',
            isGroup: '@',
            size: '@',
            type: '@',
            class: '@',
            checkedIcon: '@',
        },
        template: `<div ng-class="{ 'btn-group' : $ctrl.isGroup == 'true' }">
                      <button class="btn  btn-{{ $ctrl.type || 'default'  }} btn-{{ $ctrl.size || 'normal' }} {{ $ctrl.class }}" ng-class=" { 'margin-right-5 margin-bottom-5' : $ctrl.isGroup != 'true', 'active btn-danger' : $ctrl.isSelected(ls.id) } " ng-repeat="ls in $ctrl.list" ng-click="$ctrl.select(ls.id)">
                        <i class="fa" ng-class="{ 'fa-check-square-o' : $ctrl.isSelected(ls.id), 'fa-square-o' : !$ctrl.isSelected(ls.id) }" ng-if="$ctrl.checkedIcon"></i> <span ng-bind-html="ls.name | trustAsHtml"></span>
                      </button> 
                   </div>`,
        controller: ButtonGroup
    });

}