namespace PrideApp {
    var app = angular.module('pride');

    app.directive('select2', ['SETTINGS',  (SETTINGS): ng.IDirective => {
        return {
            restrict : 'AE',
            scope: {},
            link: (scope: any, element:any, attributes: ng.IAttributes) => {
                if(attributes.sourceUrl){
                    var options = {
                        ajax: {
                            url: attributes.sourceUrl,
                            dataType: 'json',
                            delay: 500,
                            data: function (params) {
                                return {
                                    q: params.term
                                };
                            },
                            cache: true
                        },
                        minimumInputLength: 3,
                    };

                    if(attributes.sourceUrl){
                        console.log('Source Url: ', attributes.sourceUrl);
                    }

                    element.select2( options );
                }else{
                    element.select2( );
                }

                scope.$on('select2:need:to:update', () => {
                    if(element){
                        var select2Instance = element.data('select2');
                        var resetOptions = select2Instance.options.options;
                        element.select2('destroy').select2(resetOptions)
                    }
                    console.log('select2:need:to:update - fired');
                });
            }
        }
    }]);
}