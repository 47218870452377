namespace PrideCore {
    class FormLabel {
        public static $inject:Array<string> = [
        ];

        private
        label:string;
        labelSize:number;
        value:string;

        constructor() {

        }

        $onInit() {
            this.labelSize = this.labelSize || 3;
        }
    };


    var app = angular.module('pride');

    app.component('formLabel', {
        bindings: {
            label:         '@',
            labelSize:     '@',
            value:          '@'
        },
        template: `<div class="form-group">
    <label  class="col-sm-{{ $ctrl.labelSize }} control-label">{{ $ctrl.label }} <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
    <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
        <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
            <label class="control-label label-text" style="text-align: left;">{{ $ctrl.value }}</label>
        </span>
    </div>
</div>`,
        controller: FormLabel
    });

}