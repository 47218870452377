namespace PrideApp {
    class Bill {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert',
            '$routeParams',
            'UserSessionParams',
            'orderUtils'
        ];

        inProgress:boolean;
        private
        adminMode:boolean;
        total:any;
        products:any;
        bill:any;
        isNew: boolean;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private $routeParams,
            private userSessionParams,
            private orderUtils) {
        }


        $onInit() {
            angular.forEach(this.bill.positions, (position) => {
                var product = position.product;
                product.customer_order_count = position.cnt;
                product.price = position.price;
                product.customer_order_count_prev = position.cnt;
                product.price_prev = position.price;
            });

            this.products = _.pluck(this.bill.positions, 'product');
            this.recalculate();
        }



        recalculate() {
            if(!this.products) return;
            this.total = this.orderUtils.recalculate(this.products);
        }

        approve() {
            this.PrideAlert.addConfirm(PrideAlertType.error, 'Вы действительно хотите подтвердить счет?', 'Подтверждение счета', 'Да, подтвердить').then(() => {
                this.inProgress = true;
                this.$http.post('/order/approve/' + this.bill.order_id + '/' + this.bill.id + '/', this.bill).then( (response) => {
                    this.inProgress = false;
                    this.bill.status_id = 1;
                }, (error) => {
                    this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                    this.inProgress = false;
                });
            });

        }

    };


    var app = angular.module('pride');

    app.component('bill', {
        bindings: {
            bill: '=',
            adminMode: '@'

        },
        templateUrl: 'templates/core/component/order/bill.html',
        controller: Bill
    });

}