namespace PrideApp {
    class PreorderList {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            '$routeParams',
            'numberUtils',
            'PrideAlert',
            'UserSessionParams'
        ];

        private
            preorders:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private $routeParams,
            private numberUtils,
            private PrideAlert,
            private userSessionParams) {
        }


        $onInit() {
            this.$http.get('/order/list-state/').then( (response) => {
                this.preorders = response.data;
                console.log('Preorders: ', this.preorders);
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                return;
            });
        }

        restore(id) {
            this.$http.get('/order/restore-state/?id=' + id).then( (response) => {
                window.location.href = '/';
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                return;
            });
        }

        remove(id) {
            this.$http.get('/order/remove-state/?id=' + id).then( (response) => {
                var preorder = _.findWhere(this.preorders, {id : id})
                this.preorders = _.without(this.preorders, preorder);
            }, (error) => {
                this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                return;
            });
        }

    };


    var app = angular.module('pride');

    app.component('preorders', {
        bindings: { },
        templateUrl: 'templates/core/component/preorder/list.html',
        controller: PreorderList
    });

}