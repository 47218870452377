namespace PrideApp {
    angular.module('pride').factory('downloadStreamFile', [(): any => {
        return {
            'download': (response:any, fileName:string): any => {
                var headers = response.headers();

                var filename = fileName;
                var contentType = headers['content-type'];

                var linkElement = document.createElement('a');
                try {
                    var blob = new Blob([response.data], { type: contentType });
                    var url = window.URL.createObjectURL(blob);

                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute("download", filename);

                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                    console.error('ERROR : ' + ex);
                }
            }
        };
    }]);
}