namespace PrideCore {
    class ErrorMessage {
        public static $inject:Array<string> = [
            'SETTINGS'
        ];

        private vm:any = this;

        constructor(private SETTINGS) {
        }

        $onInit() {
        }

    };


    var app = angular.module('pride');

    app.component('errorMessage', {
        bindings: {
            form: "=",
            name: '@'
        },
        template: `<div ng-messages="$ctrl.form[$ctrl.name].$error" ng-if="$ctrl.form.$submitted || $ctrl.form[$ctrl.name].$touched">
                  <div class="text-danger " ng-message="required"><i>Поле обязательно для заполнения</i></div>
                  <div class="text-danger " ng-message="minlength"><i>Поле должно быть не меньше определенной длинны</i></div>
                  <div class="text-danger " ng-message="maxlength"><i>Поле должно быть не больше определенной длинны</i></div>
                  <div class="text-danger " ng-message="email"><i>Поле должно быть email</i></div>
                  
                </div>`,
        controller: ErrorMessage
    });

}