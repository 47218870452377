namespace PrideApp {
    export class UniversalMessage {
        id: number;
        manager_id: number;
        create_date: Date;
        body: string;
        object_type_id: number;
        object_type_value: number;
        // manager: PrideManager;
        read_statuses:any;
    }
}