namespace PrideApp {
    angular.module('pride').factory('orderUtils', [ (): any => {
        return {
            'recalculate': (products): any => {
                var total:any = {
                    'count' : 0,
                    'price' : 0,
                    'volume' : 0,
                    'weight' : 0
                };
                angular.forEach(products, (product) => {
                    if(!product) return;
                    var cnt = product.customer_order_count||0;
                    total['count'] += cnt
                    total['price'] += cnt * product.price;
                    total['volume'] += cnt * product.volume;
                    total['weight'] += cnt * product.weight;
                });

                total['price'] = +(total['price']).toFixed(2);
                total['weight'] = (+total['weight']).toFixed(2);
                total['volume'] = (+total['volume']).toFixed(5);
                return total;
            },

        };
    }]);
}