namespace PrideApp {
    export enum UniversalMessageEvent {
        EVENT_UNIVERSAL_MESSAGE_ADD_VALUE = <any>"universal.message.add.value",
        EVENT_UNIVERSAL_MESSAGE_REMOVE_VALUE = <any>"universal.message.remove.value",
        EVENT_UNIVERSAL_MESSAGE_UPDATE = <any>"universal.message.update"
    }

    export class UniversalMessageUpdater {

        constructor(private $rootScope, private $elog, private $http, private $interval, private SETTINGS, private PrideAlert ) {
            $rootScope.__alerts__ = [];
        }

        const
            UPDATE_PERIOD = 15;

        public
            isRunning = false;
            objectTypeValue:any[] = [];
            objectTypeId;


        init(objectTypeId) {
            if(this.isRunning) {
                this.$elog.info('UniversalMessageUpdater already run...');
                return;
            }
            this.setObjectTypeId(objectTypeId);

            this.$elog.info('UniversalMessageUpdater ready...');

            var that:UniversalMessageUpdater = this;

            this.$rootScope.$on( UniversalMessageEvent.EVENT_UNIVERSAL_MESSAGE_ADD_VALUE, (event, data) => {
                that.addValue(data);
            });
            this.$rootScope.$on( UniversalMessageEvent.EVENT_UNIVERSAL_MESSAGE_REMOVE_VALUE, (event, data) => {
                that.removeValue(data);
            });

            this.$interval( () => {
                that.update();
            }, this.UPDATE_PERIOD * 1000);

            this.isRunning = true;
        }

        setObjectTypeId(objectTypeId){
            this.objectTypeId = objectTypeId;
            this.$elog.info('UniversalMessageUpdater set object_type_id: ', objectTypeId);
        }

        addValue(val) {
            this.objectTypeValue.push(val);
            this.$elog.info('UniversalMessageUpdater add value: ', val);
        }

        removeValue(val) {
            this.objectTypeValue = _.without(this.objectTypeValue, val);
            this.$elog.info('UniversalMessageUpdater remove value: ', val);
        }

        update(){
            this.$elog.info('UniversalMessageUpdater start update: ', this.objectTypeValue);
            if(!this.objectTypeValue.length) {
                this.$elog.info('UniversalMessageUpdater nothing to update!');
                return;
            }

            var that:UniversalMessageUpdater = this;

            this.objectTypeValue = _.uniq(this.objectTypeValue);

            return this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'list/',
                {
                    objectTypeId:     this.objectTypeId,
                    objectTypeValue:  this.objectTypeValue
                },
                {
                    params: {
                        sensor: false
                    }
                }
            ).then((response:any) => {
                this.$elog.info('UniversalMessageUpdater load messages: ', response.data);
                that.$rootScope.$broadcast( UniversalMessageEvent.EVENT_UNIVERSAL_MESSAGE_UPDATE, response.data );
            }, (error) => {
                that.PrideAlert.add( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }
    }


    angular.module('pride').factory('UniversalMessageUpdater', ['$rootScope', '$elog', '$http', '$interval', 'SETTINGS', 'PrideAlert', ($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert):UniversalMessageUpdater  => {
        return new UniversalMessageUpdater($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert);
    }]);
}