namespace PrideCore {
    class FormDate {
        public static $inject:Array<string> = [
            '$momentjs'
        ];

        private
        label:string;
        format:string;
        name:string;
        model:any;
        isShowRequiredError:boolean;
        isRequired:boolean;
        elementSize:number;
        labelSize:number;
        type:string;
        minDate:string;
        maxDate:string;
        minDateObject:any;
        maxDateObject:any;
        dateOptions:any;
        preventBeforeNow:boolean;

        constructor(private $momentjs) {

        }

        $onInit() {
            this.format = 'dd.MM.yyyy';
            this.labelSize = this.labelSize || 3;
            this.minDateObject = this.minDate ? this.$momentjs(this.minDate).toDate() : null;
            this.maxDateObject = this.maxDate ? this.$momentjs(this.maxDate).toDate() : null;
            if(this.preventBeforeNow){
                this.minDateObject = new Date();
            }

            this.dateOptions = {
                maxDate: this.maxDateObject,
                minDate: this.minDateObject,
                showWeeks: false
            };
        }
    };


    var app = angular.module('pride');

    app.component('formDate', {
        bindings: {
            label:         '@',
            name:          '@',
            model:         '=',
            isShowRequiredError: '=',
            isRequired:    '@',
            elementSize:   '@',
            labelSize:     '@',
            type:          '@',
            minDate:       '@',
            maxDate:       '@',
            preventBeforeNow: '@'
        },
        template: `<div class="form-group">
    <label  class="col-sm-{{ $ctrl.labelSize }} control-label"><span ng-bind="$ctrl.label"></span> <sup style="color: red;" ng-if="$ctrl.isRequired"> *</sup></label>
    <div class="col-sm-{{ 12 - $ctrl.labelSize }}">
        <span class="col-xs-{{ $ctrl.elementSize || 12 }}">
            <p class="input-group">
                <input type="text" class="form-control" uib-datepicker-popup="{{$ctrl.format}}" ng-model="$ctrl.model" minDate="$ctrl.minDateObject" maxDate="$ctrl.maxDateObject"  is-open="popupDate.opened" show-weeks="false" current-text="Сегодня" clear-text="Очистить" close-text="Закрыть" ng-focus="popupDate.opened=true"   ng-required="$ctrl.isRequired"  /><!--
                --><span class="input-group-btn"><!--
                  --><button type="button" class="btn btn-default" ng-click="popupDate.opened=true"><i class="glyphicon glyphicon-calendar"></i></button><!--
                --></span><!--
            --></p>
            <span ng-if="$ctrl.isRequired" class="form-field-error-message ng-clock" ng-show=$ctrl.isShowRequiredError">Необходимо заполнить поле "{{ $ctrl.label }}" </span>
        </span>
    </div>
</div>`,
        controller: FormDate
    });

}