namespace PrideApp {
    class ProductItem {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'PrideAlert',
            'ProductSearch',
            '$uibModal'
        ];

        private
            showWaitMoscowLink: boolean;
            showWaitMoscowInfo: boolean;
            showWaitInfo:boolean;
            isSrMode:boolean;
            product:any;
            linkedProducts:any[];

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private ProductSearch,
            private $uibModal) {
        }


        $onInit() {
            this.$scope.$watch(() => {
                return this.product.customer_order_count;
            }, (newVal, oldVal) => {
                if(newVal !== oldVal) {
                    this.$rootScope.$broadcast('customer.order.change.product', this.product);
                    this._getLinked(this.product.id);
                }

            });
        }

        show(code) {

            this.ProductSearch.get({'code' : code.length == 6 ? code : '0' + code}).then((products) => {
                var isolatedScope = this.$rootScope.$new(true);
                if(!products.length){
                    this.PrideAlert.addModal(PrideAlertType.error, 'По указанным Вами параметрам мы не смогли найти ни одного продукта.', 'По Вашем запросу ничего не найдено');
                    return;
                }

                isolatedScope.product = products[0];

                var modalInstance = this.$uibModal.open({
                    animation: true,
                    scope: isolatedScope,
                    template: this.isSrMode ?  `<product-item is-sr-mode="true" product="product" with-header="true"></product-item>` :  `<product-item product="product" with-header="true"></product-item>`,
                    size: 'lg'
                });

                this.$scope.$on('product.popup.close.window', () => {
                    modalInstance.close()
                });

            });
        }

        wait(code, stockId = null) {
            this._getLinked(this.product.id);
            this.$http.post(
                '/set-wait-status/',
                {code: code, stock_id: stockId}
            ).then((response) => {
                this.showWaitInfo=true
                const data = <any>response.data;
                if(data.stock_id !== 'МСК'){
                    this.showWaitMoscowLink=true
                }else if(this.showWaitMoscowLink){
                    this.showWaitMoscowInfo = true
                    this.showWaitMoscowLink = false
                }

            });
        }

        close() {
            this.$rootScope.$broadcast('product.popup.close.window');
        }

        _getLinked(productId) {
            if(this.linkedProducts) return;
            this.linkedProducts = [];
            this.$http.post('get-relation-position/', { 'product_id' : productId } ).then((response) => {
                this.linkedProducts = <any>response.data;
            });
        }

    };


    var app = angular.module('pride');

    app.component('productItem', {
        bindings: {
            product: '=',
            withHeader: '@',
            isSrMode: '@'
        },
        templateUrl: 'templates/core/component/product-search/product-item.html',
        controller: ProductItem
    });

}