namespace PrideApp {
    angular.module('pride').factory('loggingHttpInterceptor', ['$q', '$window', '$elog', '$rootScope', ($q, $window, $elog, $rootScope) => {
        return {
            // optional method
            'request': function(config) {
                // do something on success
                return config;
            },

            // optional method
            'requestError': function(rejection) {
                // do something on error
                $elog.error('responseError in global HttpInterceptor', rejection);
                return $q.reject(rejection);
            },

            // optional method
            'response': function(response) {
                // do something on success
                return response;
            },

            // optional method
            'responseError': function(rejection) {
                // do something on error

                var deferral = $q.defer();

                // PrideAlert.add(PrideAlertType.error, );

                $elog.error('Error code: ', rejection.status, rejection);
                $elog.error('Request URI: ', $window.location.href);
                $elog.error('Error URI: ', rejection.config.url);

                $rootScope.__alerts__.push({
                    type: PrideAlertType.error,
                    message: rejection.data.message
                });

                //$window.location.href = '/error' + rejection.status;

                deferral.reject(rejection);

                return deferral.promise;
            }
        };
    }]);
}