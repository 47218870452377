namespace PrideApp {
    angular.module('pride').factory('stringUtils', ['$markdown', '$sce', ($markdown, $sce): any => {
        return {
            'truncate': (text, n, useWordBoundary): any => {
                text = text ? text : '';
                n = parseInt(n);
                var isTooLong = text.length > n,
                    s_ = isTooLong ? text.substr(0, n - 1) : text;
                s_ = (useWordBoundary && isTooLong) ? s_.substr(0, s_.lastIndexOf(' ')) : s_;
                return isTooLong ? s_ + '...' : s_;
            },
            'markdown': (text): any => {
                if(!text) {
                    return null;
                }
                return $sce.trustAsHtml($markdown.makeHtml(text));
            }
        };
    }]);
}