namespace PrideApp {
    export enum UniversalMessageStatisticEvent {
        EVENT_UNIVERSAL_MESSAGE_STATISTIC_ADD_VALUE = <any>"universal.message.statistic.add.value",
        EVENT_UNIVERSAL_MESSAGE_STATISTIC_REMOVE_VALUE = <any>"universal.message.statistic.remove.value",
        EVENT_UNIVERSAL_MESSAGE_STATISTIC_UPDATE = <any>"universal.message.statistic.update"
    }

    export class UniversalMessageStatistic {

        UPDATE_PERIOD = 30;


        constructor(private $rootScope, private $elog, private $http, private $interval, private SETTINGS, private PrideAlert ) {
            $rootScope.__alerts__ = [];
        }

        public
        isRunning = false;
        objectTypeValue:any[] = [];
        objectTypeId;


        init(objectTypeId) {
            if(this.isRunning) {
                this.$elog.info('UniversalMessageStatistic already run...');
                return;
            }
            this.setObjectTypeId(objectTypeId);

            this.$elog.info('UniversalMessageStatistic ready...');

            var that:UniversalMessageStatistic = this;

            this.$rootScope.$on( UniversalMessageStatisticEvent.EVENT_UNIVERSAL_MESSAGE_STATISTIC_ADD_VALUE, (event, data) => {
                that.addValue(data);
            });
            this.$rootScope.$on( UniversalMessageStatisticEvent.EVENT_UNIVERSAL_MESSAGE_STATISTIC_REMOVE_VALUE, (event, data) => {
                that.removeValue(data);
            });

            this.$interval( () => {
                that.update();
            }, this.UPDATE_PERIOD * 1000);

            this.isRunning = true;
        }

        setObjectTypeId(objectTypeId){
            this.objectTypeId = objectTypeId;
            this.$elog.info('UniversalMessageStatistic set object_type_id: ', objectTypeId);
        }

        addValue(val) {
            this.objectTypeValue.push(val);
            this.$elog.info('UniversalMessageStatistic add value: ', val);
        }

        removeValue(val) {
            this.objectTypeValue = _.without(this.objectTypeValue, val);
            this.$elog.info('UniversalMessageStatistic remove value: ', val);
        }

        resetValue(){
            this.objectTypeValue = [];
            this.$elog.info('UniversalMessageStatistic reseted');
        }

        update(){
            this.$elog.info('UniversalMessageStatistic start update: ', this.objectTypeValue);
            if(!this.objectTypeValue.length) {
                this.$elog.info('UniversalMessageStatistic nothing to update!');
                return;
            }

            var that:UniversalMessageStatistic = this;

            this.objectTypeValue = _.uniq(this.objectTypeValue);

            return this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'statistic/',
                {
                    objectTypeId:     this.objectTypeId,
                    objectTypeValue:  this.objectTypeValue
                },
                {
                    params: {
                        sensor: false
                    }
                }
            ).then((response:any) => {
                this.$elog.info('UniversalMessageStatistic load messages: ', response.data);
                that.$rootScope.$broadcast( UniversalMessageStatisticEvent.EVENT_UNIVERSAL_MESSAGE_STATISTIC_UPDATE, response.data );
            }, (error) => {
                that.PrideAlert.add( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }
    }


    angular.module('pride').factory('UniversalMessageStatistic', ['$rootScope', '$elog', '$http', '$interval', 'SETTINGS', 'PrideAlert', ($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert):UniversalMessageStatistic  => {
        return new UniversalMessageStatistic($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert);
    }]);
}