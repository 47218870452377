namespace PrideCore {
    class NumChange {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$scope'
        ];

        private
            preventOverhead: boolean;
            overHead: boolean;
            maxVal:string;
            minVal:string;
            cnt:any;


        constructor(private SETTINGS, private $scope) {
        }

        $onInit() {
            this.check();

            this.$scope.$watch(() => {
                return this.cnt;
            }, () => {
                this.check();
            });

        }

        increase() {
            this.cnt++;
        }

        decrease() {
            this.cnt--;
        }

        check() {
            this.overHead = false;
            this.cnt = parseInt(this.cnt);
            if(!this.cnt){
                return;
            }
            if(typeof this.cnt == 'string'){
                this.cnt = this.cnt.replace(/[^-\d]/, '');
            }
            if( (this.maxVal && parseInt(this.cnt) > parseInt(this.maxVal)) || this.maxVal == '0'){
                this.overHead = true;
                if(this.preventOverhead){
                    this.cnt = parseInt(this.maxVal);
                }
            }
            if(this.minVal &&  parseInt(this.cnt) <  parseInt(this.minVal)){
                this.cnt = parseInt(this.minVal);
            }
        }

        chechKey(event) {
            event.preventDefault();
            this.check();

            if(event.keyCode == 38){
                this.increase();
            }
            if(event.keyCode == 40){
                this.decrease();
            }
        }

    };


    var app = angular.module('pride');

    app.component('numChange', {
        bindings: {
          cnt: "=",
          minVal: '@',
          maxVal: '@',
          allowManual: '@',
          isSmall: '@',
          preventOverhead: '@'
        },
        template: `<table class="num-change" ng-class="{ 'num-change-small' : $ctrl.isSmall }" ng-if="!$ctrl.allowManual">
                        <tr>
                            <td ng-click="$ctrl.decrease()"><i class="fa fa-minus"  ></i></td>
                            <td> 
                                <span  ng-class="{ 'text-danger bg-danger' : $ctrl.overHead }"  ng-bind="$ctrl.cnt"></span>
                            </td>
                            <td ng-click="$ctrl.increase()"><i class="fa fa-plus" ></i></td>
                        </tr>
                    </table>
                    <table class="num-change" ng-class="{ 'num-change-small' : $ctrl.isSmall }" ng-if="$ctrl.allowManual">
                        <tr>
                            <td ng-click="$ctrl.decrease()"><i class="fa fa-minus"  ></i></td>
                            <td class="second-item"> 
                                <input onclick="$(this).select();" ng-keyup="$ctrl.chechKey($event)"  ng-class="{ 'text-super-danger bg-danger' : $ctrl.overHead }" type="text"  ng-model="$ctrl.cnt"/> 
                            </td>
                            <td ng-click="$ctrl.increase()"><i class="fa fa-plus" ></i></td>
                        </tr>
                    </table>`,
        controller: NumChange  
    });

}