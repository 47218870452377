namespace PrideApp {
    angular.module('pride').filter('range', ['$elog', ($elog):any  => {
        return function(input, total, min, step) {
            total = parseInt(total);

            var min:any = min ? parseInt(min) : 0;
            var step:any = step ? parseInt(step) : 1;

            // $elog.info('Range Condition: ', input, total, min, step);

            for (var i=min; i<=total; i++) {
                input.push(i);
            }

            return input;
        };
    }]);
}