namespace PrideApp {
    class TopNavigation {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            'PrideAlert',
            'UserSessionParams'

        ];

        private
        manager:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private PrideAlert,
            private userSessionParams:UserSessionParams) {
        }


        $onInit() {
            this.$http.get('/admin/data/').then(
                (response:any) => {
                    this.manager = response.data.object;
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли получить данные', 'Ошибка при получении данных менеджера');
                });
        }

        logout() {
            this.$http.get('/admin/access/logout').then(
                (response:any) => {
                    window.location.href = '/admin/';
                    this.userSessionParams.set('consignee_id', null);
                    this.userSessionParams.set('account_id', null);
                    // this.userSessionParams.set('customer_basket', []);
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'При выходе из системы произошла ошибка', 'Ошибка при выходе из системы');
                });
        }


    };


    var app = angular.module('pride');

    app.component('topNavigationAdmin', {
        bindings: {
        },
        templateUrl: 'templates/admin/component/user-interface/top-navigation.html',
        controller: TopNavigation
    });

}