namespace PrideApp {

    var app = angular.module('pride', ['ui.bootstrap', 'ngSanitize', 'ngCookies', 'ngMessages', 'ngRoute', 'constants', 'LocalStorageModule', 'templates', 'angularFileUpload' ]);

    app.config( ['$httpProvider', 'SETTINGS', ($httpProvider, SETTINGS) => {
        $httpProvider.interceptors.push(function ($q) {
            return {

                // add /app_dev.php to each url requested backend api
                'request': function (config) {
                    if(SETTINGS.IS_DEBUG

                        && (
                            config.url.indexOf("/api/user_session") === 0
                        )

                    ) {
                        config.url = config.url;
                    }
                    return config || $q.when(config);
                }

            }
        });
    }]);


    app.config(['localStorageServiceProvider', (localStorageServiceProvider) => {
        localStorageServiceProvider.setPrefix('Pride');
    }]);

    // Symfony detect is request sended by ajax
    app.config(['$httpProvider', function($httpProvider) {
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection){
                    var defer = $q.defer();
                    if(rejection.status == 401){
                        console.dir(rejection);
                        window.location.href =  (window.location.href.match(/admin/) ? '/admin' : '') + '/access/login';
                    }
                    defer.reject(rejection);
                    return defer.promise;
                }
            };
        });
    }]);

    app.run( ['$cookies', ($cookies) => {
        if(!$cookies.get('user_session_uid')){
            var s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16) .substring(1);
            }
            var guid = () => {
                return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
            }

            var expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 365);

            $cookies.put('user_session_uid', guid(), {'expires': expireDate, 'path' : '/'});
        }
        console.log( 'Test: ->  ',  $cookies.getAll() );

    }]);


    // возможность менять location.path без перезагрузки контроллера
    /*
    app.run(['$route', '$rootScope', '$location', ($route, $rootScope, $location) => {
            var original = $location.path;
            $location.path = function(path, reload) {
                if (reload && reload === 'cancel') {
                    var lastRoute = $route.current;
                    var un = $rootScope.$on('$locationChangeSuccess', () => {
                        $route.current = lastRoute;
                        un();
                    });
                }
                return original.apply($location, [path]);
            };
        }])
     */



    // store constant in separate module
    angular.module('constants', []).constant('SETTINGS', {
        "DATE_FORMAT": "YYYY-MM-DD",
        "SEARCH_RESULTS_PER_PAGE" : 50,
        "IS_DEBUG" : true,  // сейчас отладка?
        // urls

        "USER_SESSION_BASE_PATH" : '/api/user_session/',
        "UNIVERSAL_MESSAGE_BASE" : "/universal-message/"

    });

     /**
    app.config([
            '$httpProvider',
            function($httpProvider) {
                $httpProvider.interceptors.push('loggingHttpInterceptor');
            }
        ]);
    */

     app.config(['$locationProvider', '$routeProvider',
         function config($locationProvider, $routeProvider) {
             $locationProvider.hashPrefix('!');

             $routeProvider.
             when('/search', {
                 template: '<product-search></product-search>',
             }).
             when('/current-order/', {
                 template: '<edit-order></edit-order>',
             }).
             when('/orders/', {
                 template: '<orders></orders>',
             }).
             when('/preorders/', {
                 template: '<preorders></preorders>',
             }).
             when('/order/:orderId', {
                 template: '<order></order>',
             }).

             when('/admin/order/search/', {
                 template: '<admin-order-search></admin-order-search>',
             }).
             when('/admin/order/:orderId', {
                 template: '<order admin-mode="true"></order>',
             }).
             when('/admin/product-search', {
                 template: '<product-search is-sr-mode="true"></product-search>',
             }).

             when('/order/:orderId/:isNew', {
                 template: '<order></order>',
             }).
             when('/about', {
                 template: '<user-about></user-about>',
             }).
             otherwise( window.location.href.match(/admin/) ? '/admin/order/search' : '/search');

         }
     ]);

}