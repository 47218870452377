namespace PrideApp {
    export class UniversalHistoryItem {

        name:string;
        createDate:Date;
        data:any;

        constructor(private $momentjs){

        }

        convertToName(data, relation) {
            var nm = [];
            angular.forEach(data, (item, key) => {
                if(!item) {
                    return;
                }

                var keyName = relation[key] || key;

                console.log(relation[key], key, item, keyName);

                if(_.isBoolean(item)){
                    return;
                }
                if(_.isDate(item)){
                    nm.push( '<i class="text-muted">' +  keyName + '</i>: ' + this.$momentjs(item).format('DD.MM.YYYY'));
                    return;
                }
                if(_.isObject(item)){
                    nm.push('<i class="text-muted">' +  keyName + '</i>: ' + item.name||item.label);
                    return;
                }
                nm.push('<i class="text-muted">' +  keyName + '</i>: ' + item);
            });
            this.name = nm.join(', ');
            return this;
        }
    }

    class UniversalHistory {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$scope',
            '$rootScope',
            'localStorage',
            'PrideAlert'
        ];

        private
            isDebug:boolean;
            recentSearch:any[];
            storageName:string;
            eventName:string;
            showAll:boolean;
            applyOnLoad:boolean;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $scope,
            private $rootScope,
            private localStorage,
            private PrideAlert) {
        }


        $onInit() {
            if(this.isDebug) {
                console.log('EventName:', this.eventName);
                console.log('StorageName:', this.storageName);
            }


            this.recentSearch = this.localStorage.get(this.storageName) || [];
            this.$scope.$on(this.eventName + '.add', (event, data:UniversalHistoryItem) => {
                data.createDate = new Date();
                this._add(data);
            });
            this.$scope.$on(this.eventName + '.close', () => {
                this.showAll = false;
            });

            if(this.applyOnLoad){
                this.apply(_.first(this.recentSearch));
            }
        }

        apply(item) {
            if(this.isDebug){
                console.log( 'Apply: ', item );
            }
            this.$rootScope.$broadcast(this.eventName + '.apply', item);
        }

        _add(item:UniversalHistoryItem) {
            if(this.isDebug){
                console.log( 'Add: ', item );
            }
            var searchHistory =  this.localStorage.get(this.storageName);
            var uniqSearchHash = [];
            angular.forEach(searchHistory, (history) => {
                uniqSearchHash.push(JSON.stringify(history.data));
            });

            if(uniqSearchHash.indexOf(JSON.stringify(item.data)) > -1){
                return;
            }

            if(!searchHistory) searchHistory = [];
            searchHistory.unshift(item);

            var searchHistoryJson = [];

            angular.forEach(searchHistory, (v) => {
                searchHistoryJson.push(JSON.stringify(v));
            });

            searchHistory = [];

            searchHistoryJson = _.uniq(searchHistoryJson);

            angular.forEach(searchHistoryJson, (v) => {
                searchHistory.push(JSON.parse(v));
            });

            this.localStorage.set(this.storageName, searchHistory);
            this.recentSearch = searchHistory;
        }

        remove(item:UniversalHistoryItem) {
            if(this.isDebug){
                console.log( 'Remove: ', item );
            }

            var searchHistory =  this.localStorage.get(this.storageName);
            searchHistory = _.without(searchHistory, _.findWhere(searchHistory, { 'createDate' :  item.createDate }));
            this.recentSearch = searchHistory;
            this.localStorage.set(this.storageName, searchHistory);
        }

        clear() {
            this.localStorage.set(this.storageName, []);
            this.recentSearch = [];
        }

    };


    var app = angular.module('pride');

    app.component('universalHistory', {
        bindings: {
            storageName : '@',
            eventName : '@',
            applyOnLoad : '@',
            isDebug : '@',

        },
        template: `
            <div class="universal-history" ng-if="$ctrl.recentSearch.length">
                <div class="row">
                    <div class="col-md-4">
                        <h4 ><nobr>История поиска</nobr></h3>
                    </div>
                    <div class="col-md-8 text-right">
                        <a href="javascript:void(0);" ng-click="$ctrl.showAll=!$ctrl.showAll"> <span ng-show="!$ctrl.showAll" >показать</span> <span ng-show="$ctrl.showAll">скрыть</span>  все (<span ng-bind="$ctrl.recentSearch.length"></span>)</a>
                        &nbsp; <a href="javascript:void(0);" ng-click="$ctrl.clear()">очистить все (<span ng-bind="$ctrl.recentSearch.length"></span>)</a>
                    </div>
                </div>
                <ul class="list-unstyled">
                    <li  ng-repeat="item in $ctrl.recentSearch track by $index" ng-show="$index==0 || $ctrl.showAll" > <span class="text-danger pull-right" style="margin-top: -3px; margin-left: 5px;"><i class="fa fa-remove" ng-click="$ctrl.remove(item)"></i></span> <span class="pull-right label label-default" ng-bind="item.createDate | momentjs: 'DD.MM.YYYY HH:mm' "></span> <span  ng-click="$ctrl.apply(item);" ng-bind-html="item.name | trustAsHtml"></span> </li>                
                </ul>
            </div>
        `,
        controller: UniversalHistory
    });

}