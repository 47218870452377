namespace PrideApp {
    declare var FileUploader : any;

    class FileUploaderClass {
        public static $inject: Array<string> = [
            'SETTINGS',
            'FileUploader',
            '$http',
            '$rootScope',
            'PrideAlert'
        ];

        private
            uploadError:boolean;
            allFilesUploadSuccessfully:boolean;
            uploader: any;
            uid: string;
            url: string;
            singleMode: boolean;

        constructor(private SETTINGS, private FileUploader, private $http, private $rootScope:ng.IRootScopeService, private PrideAlert:PrideAlert) {
        }

        $onInit() {

            this.uploader = new this.FileUploader({
                url: this.url,
                type: 'post',
                removeAfterUpload: true,
                formData: [  ],
                success: (resp) => {

                },
                error: (error) => {
                    this.PrideAlert.addModal( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
                }
            });

            this.uploader.filters.push({
                name: 'customFilter',
                fn: function(item /*{File|FileLikeObject}*/, options) {
                    return this.queue.length < 10;
                }
            });

            /*
            this.uploader.onWhenAddingFileFailed = (item , filter, options) => {
                console.info('onWhenAddingFileFailed', item, filter, options);
            };
            */

            this.uploader.onAfterAddingFile = (fileItem) => {
                this.$rootScope.$broadcast('file.upload.on.after.adding.file.' + this.uid);
                this.allFilesUploadSuccessfully = false;
                if(this.singleMode){
                    this.uploader.uploadAll();
                }
            };

            /*
            this.uploader.onAfterAddingAll = (addedFileItems) => {
                console.info('onAfterAddingAll', addedFileItems);
            };
            */

            this.uploader.onBeforeUploadItem = (item) => {

                console.info('onBeforeUploadItem', item);

            };

            /*
            this.uploader.onProgressItem = (fileItem, progress) => {
                console.info('onProgressItem', fileItem, progress);
            };

            this.uploader.onProgressAll = (progress) => {
                console.info('onProgressAll', progress);
            };


            */

            this.uploader.onSuccessItem = (fileItem, response, status, headers) => {
                console.info('onSuccessItem', fileItem, response, status, headers);
                this.$rootScope.$broadcast('file.upload.on.success.item.' + this.uid, response);
            };

            this.uploader.onCancelItem = (fileItem, response, status, headers) => {
                console.info('onCancelItem', fileItem, response, status, headers);
                this.$rootScope.$broadcast('file.upload.on.cancel.item.' + this.uid, response);
            };


            this.uploader.onCompleteItem = (fileItem, response, status, headers) => {
                console.info('onCompleteItem', fileItem, response, status, headers);
                this.$rootScope.$broadcast('file.upload.on.complete.item.' + this.uid, response);

            };

            this.uploader.onErrorItem =  (fileItem, response, status, headers) => {
                console.info('onErrorItem', response);
                this.uploadError = true;
                this.$rootScope.$broadcast('file.upload.on.error.item.' + this.uid, response);

                // this.PrideAlert.addModal( PrideAlertType.error, response, 'В процессе загрузки произошли ошибки' );
            };

            this.uploader.onCompleteAll = () => {
                this.$rootScope.$broadcast('file.upload.on.complete.all.' + this.uid);
                this.allFilesUploadSuccessfully = true;
                console.log('OnCompleteAll', 'file.upload.on.complete.all.' + this.uid);
            };

        }

        close() {
            this.$rootScope.$broadcast('file.upload.close.modal');
        }


    }
    ;


    var app = angular.module('pride');

    app.component('fileUploader', {
        bindings: {
            url: '@',
            uid: '@',
            singleMode: '@'
        },
        templateUrl: 'templates/core/component/elements/file-uploader.html',
        controller: FileUploaderClass
    });

}