namespace PrideCore {
    class Slider {
        public
            min:number;
            max:number;
            from:number;
            to:number;
            type:string;
            step:number;
            postfix:string;
            prefix:string;
            isPrettify:boolean;
            hasGrid:boolean;


        public static $inject:Array<string> = [
            '$scope',
            '$timeout',
            'SETTINGS'
        ];

        private
            vm:any = this;
            priceSlider:any = null;

        constructor(private $scope, private $timeout , private SETTINGS) {
        }

        $onInit() {
            var priceSlider = (<any>$("#price_range_slider")).ionRangeSlider({
                min:       this.min,
                max:       this.max,
                from:      this.from,
                to:        this.to,
                type:      this.type || 'double',
                step:      this.step || 1,
                postfix:   this.postfix || "",
                prefix:    this.prefix || "",
                prettify:  this.isPrettify || true,
                hasGrid:   this.hasGrid || true,
                onFinish: (slideData) => {
                    this.$timeout(() => {
                        this.from = slideData.from;
                        this.to = slideData.to;
                    });
                }
            });

            this.priceSlider = $("#price_range_slider").data("ionRangeSlider");

            var that = this;

            this.$scope.$watch(() => { return this.min }, (newVal, oldVal) => {
                if(that.priceSlider) {
                    that.priceSlider.update({
                        min:       that.min,
                        max:       that.max,
                        from:      that.from,
                        to:        that.to
                    });
                }
            });
        }

    };


    var app = angular.module('pride');

    app.component('slider', {
        bindings: {
            min:      "=",
            max:      "=",
            from:     "=",
            to:       "=",
            type:     "@",
            step:     "@",
            postfix:  "@",
            prefix:   "@",
            prettify: "@",
            hasGrid:  "@",
        },
        template: `<div class="price-slider" ><input type="text" id="price_range_slider" name="price_range" value="" /></div>`,
        controller: Slider
    });

}