namespace PrideApp {
    class TopNavigation {
        public static $inject:Array<string> = [
            'SETTINGS',
            '$elog',
            '$http',
            '$cookies',
            '$scope',
            '$rootScope',
            'PrideAlert',
            'UserSessionParams'
        ];

        private
            customer:any;
            manager:any;

        constructor(
            private SETTINGS,
            private $elog,
            private $http:ng.IHttpService,
            private $cookies,
            private $scope,
            private $rootScope,
            private PrideAlert,
            private userSessionParams:UserSessionParams) {
        }


        $onInit() {
            this.$http.get('/api/user/data/').then(
                (response:any) => {
                    this.customer = response.data.object;
                    this.manager = response.data.manager;
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'Мы не смогли получить данные', 'hide-header');
            });
            this._showPopup();
        }

        logout() {

            // удаляем текущий выбранный аккаунт
            this.userSessionParams.remove('account_id');
            this.userSessionParams.remove('consignee_id');
            this.userSessionParams.remove('customer_basket');

            this.$http.get('/api/user/logout/').then(
                (response:any) => {
                    window.location.href = '/';
                },
                (error) => {
                    this.PrideAlert.addModal(PrideAlertType.error, 'При выходе из системы произошла ошибка', 'Ошибка при выходе из системы');
                });
        }

        _showPopup() {
            if(!this.$cookies.get('initPopupAlreadyShow')){
                this.$cookies.put(
                    'initPopupAlreadyShow',
                    true,
                    {expires: new Date("October 13, 2030 11:13:00"), path: '/'});
                // this.PrideAlert.addModal(PrideAlertType.error, '<div style="width: 195px; height: 32px; margin: 283px 0 0 446px; position: absolute; cursor: pointer;" onclick="window.location.reload();"></div><img src="/bundles/app/images/srz_ng2019.jpg" style="width: 100%;">', 'hide-header', 'lg');
            }
        }


    };


    var app = angular.module('pride');

    app.component('topNavigation', {
        bindings: {
        },
        templateUrl: 'templates/core/component/user-interface/top-navigation.html',
        controller: TopNavigation
    });

}