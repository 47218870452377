namespace PrideApp {
    export enum UniversalMessageMarkAsReadEvent {
        EVENT_UNIVERSAL_MARK_AS_READ_ADD_VALUE = <any>"universal.message.mark.as.read.add.value",
        EVENT_UNIVERSAL_MARK_AS_READ_REMOVE_VALUE = <any>"universal.message.mark.as.read.remove.value",
        EVENT_UNIVERSAL_MARK_AS_READ_UPDATE = <any>"universal.message.mark.as.read.update"
    }

    export class UniversalMessageMarkAsRead {

        constructor(private $rootScope, private $elog, private $http, private $interval, private SETTINGS, private PrideAlert ) {
            $rootScope.__alerts__ = [];
        }

        public
        isRunning = false;
        objectTypeValue:any[] = [];
        objectTypeId;


        init(objectTypeId) {
            if(this.isRunning) {
                this.$elog.info('UniversalMessageMarkAsRead already run...');
                return;
            }
            this.setObjectTypeId(objectTypeId);

            this.$elog.info('UniversalMessageMarkAsRead ready...');

            var that:UniversalMessageMarkAsRead = this;

            this.$rootScope.$on( UniversalMessageMarkAsReadEvent.EVENT_UNIVERSAL_MARK_AS_READ_ADD_VALUE, (event, data) => {
                that.addValue(data);
            });
            this.$rootScope.$on( UniversalMessageMarkAsReadEvent.EVENT_UNIVERSAL_MARK_AS_READ_REMOVE_VALUE, (event, data) => {
                that.removeValue(data);
            });

            this.$interval( () => {
                that.update();
            }, 5000);

            this.isRunning = true;
        }

        setObjectTypeId(objectTypeId){
            this.objectTypeId = objectTypeId;
            this.$elog.info('UniversalMessageMarkAsRead set object_type_id: ', objectTypeId);
        }

        addValue(val) {
            this.objectTypeValue.push(val);
            this.$elog.info('UniversalMessageMarkAsRead add value: ', val);
        }

        removeValue(val) {
            this.objectTypeValue = _.without(this.objectTypeValue, val);
            this.$elog.info('UniversalMessageMarkAsRead remove value: ', val);
        }

        update(){
            this.$elog.info('UniversalMessageMarkAsRead start update: ', this.objectTypeValue);
            if(!this.objectTypeValue.length) {
                this.$elog.info('UniversalMessageMarkAsRead nothing to update!');
                return;
            }

            var that:UniversalMessageMarkAsRead = this;

            return this.$http.post( this.SETTINGS.UNIVERSAL_MESSAGE_BASE + 'mark-as-read/',
                {
                    objectTypeId:     this.objectTypeId,
                    objectTypeValue:  this.objectTypeValue
                },
                {
                    params: {
                        sensor: false
                    }
                }
            ).then((response:any) => {
                this.$elog.info('UniversalMessageMarkAsRead load messages: ', response.data);
                that.$rootScope.$broadcast( UniversalMessageMarkAsReadEvent.EVENT_UNIVERSAL_MARK_AS_READ_UPDATE, response.data );
            }, (error) => {
                that.PrideAlert.add( PrideAlertType.error, error.data.message + '<pre>' + error.data.trace + '</pre>' );
            });
        }
    }


    angular.module('pride').factory('UniversalMessageMarkAsRead', ['$rootScope', '$elog', '$http', '$interval', 'SETTINGS', 'PrideAlert', ($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert):UniversalMessageMarkAsRead  => {
        return new UniversalMessageMarkAsRead($rootScope, $elog, $http, $interval, SETTINGS, PrideAlert);
    }]);
}