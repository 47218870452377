namespace PrideCore {
    class CurrencySelect {
        currencyList = [];

        public static $inject:Array<string> = [
            '$http',
            'SETTINGS'
        ];

        private vm:any = this;

        constructor(
            private $http:ng.IHttpService,
            private SETTINGS
        ) {
        }

        $onInit() {
                this.currencyList = ['RUB', 'EUR', 'USD'];
        }
    };

    var app = angular.module('pride');

    app.component('currencySelect', {
        bindings: {
            model: "=",
            ngRequired: '@'
        },
        template: `<select style="width: 70px;" ng-options="item as item for item in $ctrl.currencyList" class="form-control col-md-1" ng-model="$ctrl.model" ng-required="{{ $ctrl.ngRequired }}"></select>`,
        controller: CurrencySelect
    });

}