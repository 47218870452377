namespace PrideCore {
    class DatePicker {
        public static $inject: Array<string> = [
            'SETTINGS',
            '$momentjs'
        ];

        private vm: any = this;

        constructor(private SETTINGS, private $momentjs) {
        }

        $onInit() {

            this.vm.format = 'dd.MM.yyyy';
            this.vm.minDateObject = this.vm.minDate ? this.$momentjs(this.vm.minDate).toDate() : null;
            this.vm.maxDateObject = this.vm.maxDate ? this.$momentjs(this.vm.maxDate).toDate() : null;
            if (this.vm.preventBeforeNow && !this.vm.minDateObject) {
                this.vm.minDateObject = new Date();
            }

            this.vm.dateOptions = {
                maxDate: this.vm.maxDateObject,
                minDate: this.vm.minDateObject,
                showWeeks: false
            };

        }

    }
    ;


    var app = angular.module('pride');

    app.component('datePicker', {
        bindings: {
            model: '=',
            ngRequired: '@',
            minDate: '@',
            maxDate: '@',
            placeholder: '@',
            preventBeforeNow: '@',

        },
        template: `<p class="input-group">
                    <input type="text" class="form-control" ng-model-options="{ timezone: 'utc' }" uib-datepicker-popup="{{$ctrl.format}}" ng-model="$ctrl.model"  datepicker-options="$ctrl.dateOptions"  is-open="popupDate.opened" current-text="Сегодня" clear-text="Очист." close-text="Закрыть" ng-focus="popupDate.opened=true" placeholder="{{$ctrl.placeholder}}"  ng-required="$ctrl.ngRequired" /><!--
                    --><span class="input-group-btn"><!--
                      --><button type="button" class="btn btn-default" ng-click="popupDate.opened=true"><i class="glyphicon glyphicon-calendar"></i></button><!--
                    --></span><!--
                --></p>
`,
        controller: DatePicker
    });

}